// for elements where it is 26px@>=1280 and 22px@<=1279
%h4-adaptive {
  @include font-size($h5-font-size);

  @include media-breakpoint-up(xl) {
    @include font-size($h4-font-size);
  }
}

%h4-adaptive-xs {
  @include font-size($h6-font-size);

  @include media-breakpoint-up(md) {
    @include font-size($h5-font-size);
  }

  @include media-breakpoint-up(xl) {
    @include font-size($h4-font-size);
  }
}

// for elements where it is 45px@>=1280 and 32px@<=1279
%h2-adaptive {
  @include font-size($h3-font-size);

  @include media-breakpoint-up(xl) {
    @include font-size($h2-font-size);
  }
}

// for elements where it is 45px@>=1280 and 32px@<=1279
%h2-adaptive-sm {
  @include font-size($h5-font-size);

  @include media-breakpoint-up(md) {
    @include font-size($h3-font-size);
  }

  @include media-breakpoint-up(xl) {
    @include font-size($h2-font-size);
  }
}

%h4-adaptive-revised {
  @include font-size($h3-font-size);

  @include media-breakpoint-up(xl) {
    @include font-size($h4-font-size);
  }
}