.hamburger {
  @extend %background-themed;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: add($navbar-height, $border-width);
  left: 0;
  width: 100%;
  bottom: 0;
  overflow: auto;

  &.closed {
    display: none;
  }

  &__menu {
    font-size: 2rem; // NR
    list-style: none;
    flex-grow: 1;
    padding: ($spacer * 1.5) $grid-gutter-width;
    margin: 0;
  }

  &__menu-item {
    &:not(:first-child) {
      margin-top: $spacer;
    }
  }

  &__footer {
    &__right {
      a {
        @include button-arrow-right(right, themed);
      }
    }
  }

  // SNS and HKUMed website on different line
  @include media-breakpoint-down(sm) {
    &__footer {
      &__left {
        padding: $spacer $grid-gutter-width;
      }
  
      &__right {
        @include border-top-themed();
        padding: $spacer $grid-gutter-width;
      }
    }
  }

  // SNS and HKUMed website on same line
  @include media-breakpoint-up(md) {
    top: add($navbar-height-md, $border-width);

    &__menu {
      padding: ($spacer * 3) $grid-gutter-width-md;
    }

    &__footer {
      @include border-top-themed();
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__left {
        padding: $spacer $grid-gutter-width-md;
      }
  
      &__right {
        padding: $spacer $grid-gutter-width-md;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}
