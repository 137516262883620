// make sure there is NO space between the opening tag and the first child tag
@mixin height-support($height-factor: 1) {
  display: flex;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 0;

    @if type-of($height-factor) == "number" and unitless($height-factor) {
      padding-bottom: percentage($height-factor);
    } @else {
      padding-bottom: $height-factor;
    }
  }
}
