.featured-article {
  @extend %container;
  @include gutter-multiplier(1, 'margin-top', 'margin-bottom');

  &__image {
    display: none;
  }

  &:hover {
    .article-cell__button::after {
      @include block-arrow-animation();
    }
  }

  &__inner {
    position: relative;
  }

  @include media-breakpoint-down(sm){
    &__image {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      padding-bottom: 50%;
      background: center / cover no-repeat;
      z-index: 5;
    }
  }

  @include media-breakpoint-up(md) {
    &__inner {
      display: flex;
    }

    &__image {
      display: block;
      flex: auto;
      background: center / contain no-repeat;
    }

    &__right {
      width: calc( (100% - #{$grid-gutter-width-md} * 1) / 2 );

      .article-cell__image {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__right {
      width: calc( (100% - #{$grid-gutter-width-xl} * 2) / 3 );
    }
  }
}
