@mixin gutter-multiplier($multiplier, $properties...) {
  @each $property in $properties {
    #{$property}: $grid-gutter-width * $multiplier;
  }

  @include media-breakpoint-up(md) {
    @each $property in $properties {
      #{$property}: $grid-gutter-width-md * $multiplier;
    }
  }

  @include media-breakpoint-up(xl) {
    @each $property in $properties {
      #{$property}: $grid-gutter-width-xl * $multiplier;
    }
  }
}
