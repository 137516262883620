.articles-list {
  @extend %container;
  @include gutter-multiplier(1, 'margin-top');
  @include gutter-multiplier(2, 'margin-bottom');
  padding-bottom: 0.1px; // calculation is correct without this line, but with it dev console can properly highlight the area

  &__cells {
    @include make-row-resp();
    @include gutter-multiplier(-1, 'margin-bottom');
  }

  &__cell {
    @include make-col-ready-resp();
    @include gutter-multiplier(1, 'margin-bottom');

    .article-cell {
      height: 100%; // ensure the whole row's cell has same height
    }
  }

  @include media-breakpoint-down(sm) {
    &__cells {
      @include row-cols(1);
    }
  }

  @include media-breakpoint-up(md) {
    &__cells {
      @include row-cols(2);
    }
  }

  @include media-breakpoint-up(xl) {
    &__cells {
      @include row-cols(3);
    }
  }
}
