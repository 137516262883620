.image-module {
  @extend %container;
  margin-top: $spacer * 3;
  margin-bottom: $spacer * 3;
  
  img {
    width: 100%;
  }

  figcaption {
    text-align: center;
    margin-top: $spacer * 0.5;
  }

  &--fw {
    max-width: none !important;
    padding-left: 0;
    padding-right: 0;
  }
}