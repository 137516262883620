.trifilter {
    @extend %container;
    @include compat-module-base();
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2;
    $this: &;

    &__row {
        @include make-row-resp();
    }

    &__group {
        @include make-col-ready-resp();

        @include media-breakpoint-down(sm) {
            &:not(:first-child) {
                margin-top: $spacer / 2;
            }
        }

        @include media-breakpoint-up(md) {
            flex: 1 0 0px;
        }

        &.dropdown-select-inited {
            #{$this}__input {
                display: none;
            }
        }
    }

    &__dropdown-wrapper {
        z-index: 198;
        position: relative;
    }

    &__dropdown-selected {
        position: relative;
        font-size: 18px;
        line-height: 48px !important;
        font-family: $font-family-sans-serif;
        font-weight: 400;
        width: 100%;
        // height: $font-size-base / 18 * 50;
        height: 50px !important;
        padding-left: $spacer * 0.75;
        padding-right: add($spacer * 1.5, $symbol-side);
        border: $border-width solid $border-color;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;

        &:after {
            position: absolute;
            right: 0;
            top: 0;
            width: $font-size-base / 18 * 50;
            height: $font-size-base / 18 * 50;
            content: '';
            display: block;
            background: escape-svg(map-get($button-arrow-imgs-black, down)) right ($spacer * 0.75) center / $symbol-side no-repeat, white;
        }

        body.dark-theme & {
            color: $white;

            &:after {
                background: escape-svg(map-get($button-arrow-imgs-white, down)) right ($spacer * 0.75) center / $symbol-side no-repeat, #1e1e1e;
            }
        }

        &:focus {
            outline: 0;
            border-color: $black;

            body.dark-theme & {
                border-color: $white;
            }
        }
    }

    &__dropdown-search-wrapper {
        height: 36px;
        padding: 2px 15px;
        margin-bottom: 10px;
    }

    &__dropdown-search {
        margin: 0;
        display: block;
        width: 100%;
        line-height: 34px !important;
        border: $border-width solid $border-color !important;
        outline: none !important;
        padding: 0 10px !important;
        height: 36px !important;
        border-radius: 0 !important;

        &:focus {
            border-color: $gray-400 !important;
        }

        body.dark-theme & {
            border-color: $white !important;
            background-color: transparent !important;
            color: $white !important;
        }
    }

    &__dropdown-wrapper ul {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        overflow-x: hidden;
    }

    &__dropdown-toggle {
        @extend %styled-scrollbar;
        display: none;
        position: absolute;
        width: 100%;
        top: calc(100% - 1px);
        left: 0;
        max-height: calc(600% + 1px);
        overflow-y: auto;
        background-color: $white;
        border-left: $border-width solid $border-color;
        border-right: $border-width solid $border-color;
        border-bottom: $border-width solid $border-color;

        body.dark-theme & {
            background-color: $gray-900;
        }
    }

    &__dropdown-toggle > ul > li {
        font-weight: 400;
        &.no-match{
            opacity: 0.4;
            span{
                cursor: default !important;
            }
        }
    }

    &__dropdown-toggle > ul li {
        &.no-match{
            opacity: 0.4;
            span{
                cursor: default !important;
            }
        }
    }

    &__dropdown-toggle > ul span {
        display: block;
        font-size: 14px;
        line-height: 18px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 15px;
        // white-space: nowrap;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
        &.optgroup-title {
            font-weight: bold;
        }

        &.option-title {
            cursor: pointer;

            &.hidden {
                display: none;
            }

            &:hover {
                background-color: $gray-100;

                body.dark-theme & {
                    background-color: $gray-600;
                }
            }
        }
    }

    &__dropdown-toggle .optgroup-wrapper.hidden {
        display: none;
    }

    &__dropdown-toggle > ul > li > ul span.option-title {
        padding-left: 30px;
    }

    &__dropdown-select-opened {
        z-index: 199;

        #{$this}__dropdown-toggle {
            display: block;
        }

        #{$this}__dropdown-wrapper {
            border-bottom-color: transparent;
        }
    }



    &__input {
        @extend %unselect;
        width: 100%;
        height: $font-size-base / 18 * 50;
        padding-left: $spacer * 0.75;
        padding-right: add($spacer * 1.5, $symbol-side);
        border: $border-width solid $border-color;
        background: escape-svg(map-get($button-arrow-imgs-black, down)) right ($spacer * 0.75) center / $symbol-side no-repeat;

        body.dark-theme & {
            color: $white;
            background: escape-svg(map-get($button-arrow-imgs-white, down)) right ($spacer * 0.75) center / $symbol-side no-repeat;
        }

        &:focus {
            outline: 0;
            border-color: $black;

            body.dark-theme & {
                border-color: $white;
            }
        }
    }
}
