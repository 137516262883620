.article-text-section {
  @extend %container;
  @include font-size($article-text-font-size);
  margin-top: $spacer * 3;
  margin-bottom: $spacer * 3;

  *:first-child {
    margin-top: 0 !important;
  }

  *:last-child {
    margin-bottom: 0 !important;
  }

  a {
    color: $primary;
  }

  // in theory editors should not add h1 and h2, but for the sake of completeness
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    margin-top: $spacer * 3;
    margin-bottom: $spacer;
  }

  h4, h5, h6 {
    font-weight: bold;
  }

  h6 {
    // in case h6 is even smaller then the article text
    @if ($h6-font-size < $article-text-font-size) {
      @include font-size($article-text-font-size);
    }
  }

  @include media-breakpoint-up(lg) {
    h1, h2, h3 {
      margin-top: $spacer * 4;
    }
  }
}
