@mixin the-button-arrow($direction: right, $color-scheme: black) {
  // be sure to set your own position!
  display: block;
  width: $button-arrow-width;
  height: $button-arrow-height;
  background: center / $button-arrow-width $button-arrow-height no-repeat;
  
  @if $color-scheme == black {
    background-image: escape-svg(map-get($button-arrow-imgs-black, $direction));
  } @else if $color-scheme == white {
    background-image: escape-svg(map-get($button-arrow-imgs-white, $direction));
  } @else if $color-scheme == primary {
    background-image: escape-svg(map-get($button-arrow-imgs-primary, $direction));
  } @else if $color-scheme == themed {
    background-image: escape-svg(map-get($button-arrow-imgs-black, $direction));

    @include dark-theme() {
      background-image: escape-svg(map-get($button-arrow-imgs-white, $direction));
    }
  } @else {
    @error "Unknown color-scheme #{$color-scheme}.";
  }
}

@keyframes block-arrow-animation {
  0%  {opacity: 1; margin: 0; }
  20% {opacity: 0; margin-left: 50%; }
  21% {opacity: 0; margin-left: -50%; }
  45% {opacity: 1; margin: 0; }
}
@mixin block-arrow-animation() {
  animation: block-arrow-animation 1.8s infinite linear;
}

@mixin button-arrow-right($direction: right, $color-scheme: black) {
  position: relative;
  padding-right: $button-arrow-width + $spacer / 2;

  &::after {
    @include the-button-arrow($direction, $color-scheme);
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin button-arrow-left($direction: right, $color-scheme: black) {
  position: relative;
  padding-left: $button-arrow-width + $spacer / 2;

  &::before {
    @include the-button-arrow($direction, $color-scheme);
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
