.page-footer {
  .container {
    @extend %container;
  }

  &__top {
    @include border-top-themed();
    padding-top: $spacer * 0.75;
    padding-bottom: $spacer * 0.75;

    &__inner {
      display: flex;
      justify-content: space-between;
    }

    &__back {
      @include button-arrow-left(left, themed);
      @include font-size($font-size-lg);
    }

    &__b2top {
      @include button-arrow-right(up, themed);
      @include font-size($font-size-lg);
    }

    @include media-breakpoint-up(lg) {
      padding-top: $spacer * 1.25;
      padding-bottom: $spacer * 1.25;
    }
  }

  &__middle {
    // padding-top: $spacer * 2;
    // padding-bottom: $spacer * 2;

    &__title {
      @extend %h4-adaptive;
      margin-bottom: $spacer;
    }

    &__content {
      color: $text-muted;

      a {
        color: $primary;
        &:hover{
          color: $white;
        }
      }
    }

    // 1 col
    @include media-breakpoint-down(sm) {
      &__cell {
        @include border-top-themed();
        padding: ($spacer * 2) $grid-gutter-width;
      }
    }

    // 2 col
    @include media-breakpoint-only(md) {
      display: flex;
      flex-wrap: wrap;

      &__cell {
        @include border-top-themed();
        flex: 1 0 50%;
        padding: $grid-gutter-width-md;

        // the spacing between a and b a single gutter-width
        &--a { padding-right: $grid-gutter-width-md / 2; }
        &--b { padding-left: $grid-gutter-width-md / 2; }
      }
    }

    // 3 col (but spacing is still based on md)
    @include media-breakpoint-up(lg) {
      @include border-top-themed();
      display: flex;
      padding-left: $grid-gutter-width-md / 2;
      padding-right: $grid-gutter-width-md / 2;

      &__cell {
        flex: 1 0 33%;
        padding: $grid-gutter-width-md ( $grid-gutter-width-md / 2 );
      }

      &__content {
        max-width: 19em;
      }
    }

    // adjust spacing for xl and limit cell size
    @include media-breakpoint-up(xl) {
      padding-left: $grid-gutter-width-xl / 2;
      padding-right: $grid-gutter-width-xl / 2;
      justify-content: center;

      &__cell {
        max-width: ($container-max-width - $grid-gutter-width-xl) / 3;
        padding: $grid-gutter-width-xl ( $grid-gutter-width-xl / 2 );
      }
    }
  }

  &__bottom {
    @include font-size($font-size-sm);
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    color: $text-muted;
  }
}