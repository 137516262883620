// when title is short, the title and sharer should stay on the same line
// when title is long, the sharer should move to a new line
// there should be proper spacing in both cases
.page-header {
  @extend %container;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &__image{
    position: absolute;
    top: 0;
    left: 40px;
    right: 40px;
    height: 100%;
    background: right top / auto 71% no-repeat;
    z-index: 1;
  }

  &__title {
    @include font-size($h1-font-size);
    flex: auto;
    margin-bottom: $spacer;
    z-index: 2;
  }

  &__sharer {
    margin-bottom: $spacer;
    margin-right: 20px;
    z-index: 3;
  }

  &__subtext {
    @include font-size($h4-font-size);
    z-index: 4;

    a {
      color: $primary;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2 - $spacer;

    &--has-image {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: $spacer * 2;
      padding-bottom: $spacer * 2 - $spacer;
    }

    &__subtext {
      margin-top: $spacer * 2 - $spacer;
      margin-bottom: $spacer;
    }
    &__image{
      left: 20px;
      right: 20px;
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: $spacer * 2.75;
    margin-bottom: $spacer * 3.25 - $spacer;
    &__image{
      background-size: auto 100%;
      left: 30px;
      right: 30px;
    }

    &--has-image {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: $spacer * 2.75;
      padding-bottom: $spacer * 3.25 - $spacer;
    }

    &__sharer {
      align-self: flex-end;
      flex: 0 0 auto;
    }

    &__subtext {
      @include font-size($h4-font-size);
      line-height: 1.31;
      flex-basis: 50%;
      margin-top: $spacer * 3.25 - $spacer;
      margin-bottom: $spacer * 3.25;
      margin-right: 50%;
      order: 3;

      a {
        color: $primary;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &__image{
      left: 40px;
      right: 40px;
    }
  }
}
