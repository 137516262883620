// no gutter between columns nor beside (outer) columns
.multi-column {
  @include compat-module-base();
  @extend %container;

  > .row {
    @include make-row-resp();

    > { // .col
      @include make-grid-columns-resp();
    }

    > .col--child-px-0 > * {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
