.filterable-list {
  @extend %container;
  @include compat-module-base();
  margin-top: $spacer * 2;
  margin-bottom: $spacer * 3;
  padding-top: 0.1px; // enclose child node margin
  padding-bottom: 0.1px;

  &__no-result {
    margin-top: $spacer * 1;
    margin-bottom: $spacer * 3;
    text-align: center;

    &__title {
      @extend %h4-adaptive;
      line-height: 1.31;

      &::before {
        content: '';
        display: block;
        margin: 0 auto ($spacer * 0.5);
        width: $symbol-side * 2;
        height: $symbol-side * 2;
        background: escape-svg($list-no-result-icon) center / contain no-repeat;
      }
    }

    &__text {
      margin-top: $spacer * 1.5;
    }
  }

  &__list {
    @extend %styled-scrollbar;
    font-size: $font-size-base; // NR
    list-style: none;
    padding: 0;

    @include compat-css() {
      @at-root {
        ol#{&} { // beat the padding left specificity
          // @extend %container;
          padding-left: 0;
        }
      }
    }
  }

  &__item {
    &.odd {
      background-color: $gray-100;
      body.dark-theme &{
        background-color: $gray-100-20;
      }
    }

    &.even {
      background-color: $white;
      body.dark-theme &{
        background-color: $white-10;
      }
    }

    &.match {
      // class added by JS already, available to use
      $__future-proof: 1;
    }

    &.mismatch {
      display: none;
    }

    &__date {
      color: $text-muted;
      body.dark-theme &{
        color: $white;
      }
    }

    &__content {
      p {
        margin: 0 0 0.25em 0;
      }

      a {
        @include button-arrow-left($color-scheme: primary);
        display: inline-block;
        color: $primary;
      }
    }
  }

  &__control {
    background-color: $primary;

    &__show-more {
      @extend %unbutton;
      width: 100%;
      height: $font-size-base / 18 * 50;
      text-align: center;
    }
  }

  // one column
  @include media-breakpoint-down(lg) {
    &__item {
      min-height: 11em;
      padding: ($spacer * 0.75) $grid-gutter-width;

      &__date {
        margin-bottom: $spacer;
      }
    }
  }

  // switch to full width
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;

    &__no-result {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
  }

  // switch back to two column
  @include media-breakpoint-up(xl) {
    &__no-result {
      margin-top: $spacer * 3;
      margin-bottom: $spacer * 7;
    }

    &__list {
      font-size: $font-size-bp; // NR
    }

    &__item {

      min-height: 6.5em;
      display: flex;

      &__date {
        width: calc( (100% - #{$grid-gutter-width-xl} * 2) / 3 );
        padding: ($spacer * 0.75) 0
          ($spacer * 0.75) ($grid-gutter-width-xl / 2);
      }

      &__content {
        flex: 1 0 0px;
        padding: ($spacer * 0.75) ($grid-gutter-width-xl * 1.25)
          ($spacer * 0.75) ($grid-gutter-width-xl);
      }
    }
  }
}
