.article-cell {
  $this: &;
  @include height-support();
  @include card-themed();
  position: relative;

  &__content > * {
    position: relative;
    z-index: 10; // give space for background image
  }

  &__content {
    padding: ($spacer * 0.75) $spacer
      ($square-button-side + $spacer) $spacer;
  }

  &__date {
    color: $text-muted;
    margin-bottom: $spacer * 0.5;
  }

  &__title {
    @include font-size($h4-font-size);
  }

  &__desc {
    @include font-size($font-size-sm);
    margin-top: $spacer * 0.5;
  }

  &__image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    background: center / cover no-repeat;
    z-index: 5;
  }

  &__button {
    position: absolute;
    right: 0;
    bottom: 0;
    width: $square-button-side;
    height: $square-button-side;
    background-color: $primary;
    overflow: hidden;

    &::after {
      @include the-button-arrow();
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      #{$this}:hover & {
        @include block-arrow-animation();
      }
    }
  }

  &--has-image {
    #{$this}__content {
      padding-bottom: add(50%, $spacer);
    }
  }

  &--cover {
    color: $white;

    #{$this}__date {
      display: none;
    }

    #{$this}__image {
      height: 100%;
    }
  }

  &--larger-text {
    #{$this}__title {
      @include font-size($h2-font-size);

      @include media-breakpoint-up(md) {
        @include font-size($h3-font-size);
        padding-top: $spacer * 0.25; // visual compensation
      }
    }

    #{$this}__desc {
      @include font-size($font-size-bp);
      margin-top: $spacer * 0.75;
    }
  }

  &:hover{
    #{$this}__title{
      color: $primary;
    }
    // #{$this}__desc{
    //   opacity: 0.5;
    // }
  }

  @include media-breakpoint-up(xl) {
    &__content {
      padding: $spacer ($spacer * 1.25)
        ($square-button-side-xl + $spacer) ($spacer * 1.25);
    }

    &__date {
      margin-bottom: $spacer * 0.25;
    }

    &__title {
      line-height: 1.31;
    }

    &__desc {
      margin-top: $spacer * 0.75;
    }

    &__button {
      width: $square-button-side-xl;
      height: $square-button-side-xl;
    }

    &--larger-text {
      #{$this}__desc {
        margin-top: $spacer * 1.25;
      }
    }
  }
}
