$styled-scrollbar-background: white;
$styled-scrollbar-color: $gray-900;

%styled-scrollbar {
  @function make-arrow($rotation, $color: $styled-scrollbar-color) {
    @return escape-svg(url("data:image/svg+xml,<svg height='7' width='7' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg'><polygon transform='rotate(#{$rotation},3.5,3.5)' points='0,6 7,6 3.5,2' style='fill:#{$color}' /></svg>"));
  }

  scrollbar-track-color: $styled-scrollbar-background;
  scrollbar-shadow-color: $styled-scrollbar-background;
  scrollbar-arrow-color: $styled-scrollbar-color;
  scrollbar-face-color: $styled-scrollbar-color;

  &::-webkit-scrollbar {
    width: 17px;
    background-color: $styled-scrollbar-background;
  }

  &::-webkit-scrollbar-button {
    height: 17px;
    background-size: 7px;
    background-position: center;
    background-repeat: no-repeat;

    &:vertical:decrement { background-image: make-arrow(0); }
    &:vertical:increment { background-image: make-arrow(180); }
    &:horizontal:decrement { background-image: make-arrow(270); }
    &:horizontal:increment { background-image: make-arrow(90); }
  }

  &::-webkit-scrollbar-thumb {
    background-color: $styled-scrollbar-color;
    border: 0 solid $styled-scrollbar-background;

    &:vertical {
      border-width: 0 2px;
      body.dark-theme &{
        border-width: 0;
      }
    }

    &:horizontal {
      border-width: 2px;
    }
  }
}

$styled-scrollbar-background-invert: transparent;
$styled-scrollbar-color-invert: $white;

%styled-scrollbar-invert{
  @function make-arrow-invert($rotation, $color: $styled-scrollbar-color-invert) {
    @return escape-svg(url("data:image/svg+xml,<svg height='7' width='7' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg'><polygon transform='rotate(#{$rotation},3.5,3.5)' points='0,6 7,6 3.5,2' style='fill:#{$color}' /></svg>"));
  }

  scrollbar-track-color: $styled-scrollbar-background-invert;
  scrollbar-shadow-color: $styled-scrollbar-background-invert;
  scrollbar-arrow-color: $styled-scrollbar-color-invert;
  scrollbar-face-color: $styled-scrollbar-color-invert;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: $styled-scrollbar-background-invert;
  }

  &::-webkit-scrollbar-button {
    height: 10px;
    background-size: 7px;
    background-position: center;
    background-repeat: no-repeat;

    &:vertical:decrement { background-image: make-arrow-invert(0); }
    &:vertical:increment { background-image: make-arrow-invert(180); }
    &:horizontal:decrement { background-image: make-arrow-invert(270); }
    &:horizontal:increment { background-image: make-arrow-invert(90); }
  }

  &::-webkit-scrollbar-thumb {
    background-color: $styled-scrollbar-color-invert;
    border: 0 solid $styled-scrollbar-background-invert;

    &:vertical {
      border-width: 0 2px;
    }

    &:horizontal {
      border-width: 2px;
    }
  }
}