%unbutton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  @include compat-css() {
    letter-spacing: 0;
    text-transform: none;
    margin: 0;
  }
}

%unselect {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  @include compat-css() {
    margin: 0;
    border-radius: 0;
  }

  &::-ms-expand {
    display: none;
  }
}
