.seo-content {
  @extend %container;
  padding-top: $spacer * 2; // cannot apply background (image) on margin
  padding-bottom: $spacer * 2;
  background: right bottom / auto 56% no-repeat;

  &__title {
    font-size: 1.625rem; // NR 26
    margin-bottom: $spacer;
  }

  &--has-image {
    padding-bottom: $spacer * 6;
  }

  @include media-breakpoint-up(md) {
    $this: &;
    background-size: auto 100%;

    &--has-image {
      #{$this}__content {
        width: 48%;
        max-width: 33em;
      }
    }
  }
}
