.sb-quick-links {
  @include compat-module-base();
  @include gutter-multiplier(1, 'padding', 'margin-top', 'margin-bottom');
  background-color: $gray-100;
  
  &__title {
    @extend %h4-adaptive;
  }

  &__list-wrapper {
    position: relative;
    margin-top: $spacer * 1.25;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    @include compat-css() {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  &__list-item {
    border-bottom: $border-width solid rgba(127, 127, 127, 0.13);

    &__link {
      display: flex;
      position: relative;
      margin: ($spacer * 0.75) 0;
      min-height: 4.5em;

      @include compat-css() {
        color: inherit;
      }
    }

    &__date {
      width: 4.6875rem; // 75
      flex: 0 0 auto;

      // .day and .month is intentionally unresponsive according to design
      .day,
      .month {
        position: absolute;
        left: 0;
        color: $primary;
      }
      
      .day {
        font-size: 2.5rem; // 40
        line-height: 1;
        top: -0.125rem; // -2
        font-weight: normal;
      }
      
      .month {
        font-size: 0.75rem; // 12
        line-height: 1.5;
        top: 2.75rem; // 44
        text-transform: uppercase;
        letter-spacing: 0.083em;
      }
    }

    &__title {
      flex-grow: 1;
    }

    &__image {
      flex: 0 0 auto;

      img {
        margin-left: 0.9375rem; // 15
        width: 4.6875rem; // 75
      }
    }
  }

  &__links {
    margin-top: $spacer;
    
    a {
      color: $primary;

      &::after {
        @include the-button-arrow($color-scheme: primary);
        content: '';
        display: inline-block;
        margin-left: $spacer * 0.5;
        vertical-align: middle;
      }
    }
  }
}