/*!
 * Bootstrap Reboot v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", "helvetica", "arial", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  color: #323232;
  text-align: left;
  background-color: #fff;
}

@media (max-width: 1440px) {
  body {
    font-size: calc(0.875rem + 0.27778vw) ;
  }
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1.5em;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #828282;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

@media (max-width: 1440px) {
  legend {
    font-size: calc(0.95rem + 0.61111vw) ;
  }
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

@keyframes block-arrow-animation {
  0% {
    opacity: 1;
    margin: 0;
  }
  20% {
    opacity: 0;
    margin-left: 50%;
  }
  21% {
    opacity: 0;
    margin-left: -50%;
  }
  45% {
    opacity: 1;
    margin: 0;
  }
}

.hnav__hamburger-button, .image-slider__arrow, .filterable-list__control__show-more, .video-popup__close-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.trifilter__input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.trifilter__input::-ms-expand {
  display: none;
}

.page-footer__middle__title, .masonry-articles__title, .filterable-list__no-result__title, .sb-quick-links__title {
  font-size: 1.375rem;
}

@media (max-width: 1440px) {
  .page-footer__middle__title, .masonry-articles__title, .filterable-list__no-result__title, .sb-quick-links__title {
    font-size: calc(0.925rem + 0.5vw) ;
  }
}

@media (min-width: 1280px) {
  .page-footer__middle__title, .masonry-articles__title, .filterable-list__no-result__title, .sb-quick-links__title {
    font-size: 1.625rem;
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .page-footer__middle__title, .masonry-articles__title, .filterable-list__no-result__title, .sb-quick-links__title {
    font-size: calc(0.975rem + 0.72222vw) ;
  }
}

.video-cell__title, .download-cell__title, .download-cell__link {
  font-size: 1.125rem;
}

@media (max-width: 1440px) {
  .video-cell__title, .download-cell__title, .download-cell__link {
    font-size: calc(0.875rem + 0.27778vw) ;
  }
}

@media (min-width: 680px) {
  .video-cell__title, .download-cell__title, .download-cell__link {
    font-size: 1.375rem;
  }
}

@media (min-width: 680px) and (max-width: 1440px) {
  .video-cell__title, .download-cell__title, .download-cell__link {
    font-size: calc(0.925rem + 0.5vw) ;
  }
}

@media (min-width: 1280px) {
  .video-cell__title, .download-cell__title, .download-cell__link {
    font-size: 1.625rem;
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .video-cell__title, .download-cell__title, .download-cell__link {
    font-size: calc(0.975rem + 0.72222vw) ;
  }
}

.masonry-latest__title {
  font-size: 2rem;
}

@media (max-width: 1440px) {
  .masonry-latest__title {
    font-size: calc(1.05rem + 1.05556vw) ;
  }
}

@media (min-width: 1280px) {
  .masonry-latest__title {
    font-size: 2.8125rem;
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .masonry-latest__title {
    font-size: calc(1.2125rem + 1.77778vw) ;
  }
}

.hnav__left__self {
  font-size: 1.375rem;
}

@media (max-width: 1440px) {
  .hnav__left__self {
    font-size: calc(0.925rem + 0.5vw) ;
  }
}

@media (min-width: 680px) {
  .hnav__left__self {
    font-size: 2rem;
  }
}

@media (min-width: 680px) and (max-width: 1440px) {
  .hnav__left__self {
    font-size: calc(1.05rem + 1.05556vw) ;
  }
}

@media (min-width: 1280px) {
  .hnav__left__self {
    font-size: 2.8125rem;
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .hnav__left__self {
    font-size: calc(1.2125rem + 1.77778vw) ;
  }
}

.masonry-sqlink__content {
  font-size: 2rem;
}

@media (max-width: 1440px) {
  .masonry-sqlink__content {
    font-size: calc(1.05rem + 1.05556vw) ;
  }
}

@media (min-width: 1280px) {
  .masonry-sqlink__content {
    font-size: 1.625rem;
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .masonry-sqlink__content {
    font-size: calc(0.975rem + 0.72222vw) ;
  }
}

.hnav, .breadcrumb, .page-header, .page-footer .container, .featured-article, .articles-list, .article-header, .article-text-section, .image-module, .image-slider__container, .related-links, .seo-content, .hr, .masonry, .video-grid, .trifilter, .filterable-list, .multi-column, .mm-set-header {
  width: 100%;
  max-width: 1440px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 680px) {
  .hnav, .breadcrumb, .page-header, .page-footer .container, .featured-article, .articles-list, .article-header, .article-text-section, .image-module, .image-slider__container, .related-links, .seo-content, .hr, .masonry, .video-grid, .trifilter, .filterable-list, .multi-column, .mm-set-header {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1280px) {
  .hnav, .breadcrumb, .page-header, .page-footer .container, .featured-article, .articles-list, .article-header, .article-text-section, .image-module, .image-slider__container, .related-links, .seo-content, .hr, .masonry, .video-grid, .trifilter, .filterable-list, .multi-column, .mm-set-header {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.hnav-outer, .hamburger {
  background-color: #fff;
  color: #323232;
}

.dark-theme .hnav-outer, .dark-theme .hamburger {
  background-color: #1e1e1e;
  color: #fff;
}

.trifilter__dropdown-toggle, .filterable-list__list {
  scrollbar-track-color: white;
  scrollbar-shadow-color: white;
  scrollbar-arrow-color: #1e1e1e;
  scrollbar-face-color: #1e1e1e;
}

.trifilter__dropdown-toggle::-webkit-scrollbar, .filterable-list__list::-webkit-scrollbar {
  width: 17px;
  background-color: white;
}

.trifilter__dropdown-toggle::-webkit-scrollbar-button, .filterable-list__list::-webkit-scrollbar-button {
  height: 17px;
  background-size: 7px;
  background-position: center;
  background-repeat: no-repeat;
}

.trifilter__dropdown-toggle::-webkit-scrollbar-button:vertical:decrement, .filterable-list__list::-webkit-scrollbar-button:vertical:decrement {
  background-image: url("data:image/svg+xml,%3csvg height='7' width='7' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg'%3e%3cpolygon transform='rotate%280,3.5,3.5%29' points='0,6 7,6 3.5,2' style='fill:%231e1e1e' /%3e%3c/svg%3e");
}

.trifilter__dropdown-toggle::-webkit-scrollbar-button:vertical:increment, .filterable-list__list::-webkit-scrollbar-button:vertical:increment {
  background-image: url("data:image/svg+xml,%3csvg height='7' width='7' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg'%3e%3cpolygon transform='rotate%28180,3.5,3.5%29' points='0,6 7,6 3.5,2' style='fill:%231e1e1e' /%3e%3c/svg%3e");
}

.trifilter__dropdown-toggle::-webkit-scrollbar-button:horizontal:decrement, .filterable-list__list::-webkit-scrollbar-button:horizontal:decrement {
  background-image: url("data:image/svg+xml,%3csvg height='7' width='7' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg'%3e%3cpolygon transform='rotate%28270,3.5,3.5%29' points='0,6 7,6 3.5,2' style='fill:%231e1e1e' /%3e%3c/svg%3e");
}

.trifilter__dropdown-toggle::-webkit-scrollbar-button:horizontal:increment, .filterable-list__list::-webkit-scrollbar-button:horizontal:increment {
  background-image: url("data:image/svg+xml,%3csvg height='7' width='7' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg'%3e%3cpolygon transform='rotate%2890,3.5,3.5%29' points='0,6 7,6 3.5,2' style='fill:%231e1e1e' /%3e%3c/svg%3e");
}

.trifilter__dropdown-toggle::-webkit-scrollbar-thumb, .filterable-list__list::-webkit-scrollbar-thumb {
  background-color: #1e1e1e;
  border: 0 solid white;
}

.trifilter__dropdown-toggle::-webkit-scrollbar-thumb:vertical, .filterable-list__list::-webkit-scrollbar-thumb:vertical {
  border-width: 0 2px;
}

body.dark-theme .trifilter__dropdown-toggle::-webkit-scrollbar-thumb:vertical, body.dark-theme .filterable-list__list::-webkit-scrollbar-thumb:vertical {
  border-width: 0;
}

.trifilter__dropdown-toggle::-webkit-scrollbar-thumb:horizontal, .filterable-list__list::-webkit-scrollbar-thumb:horizontal {
  border-width: 2px;
}

.masonry-articles__list-wrapper {
  scrollbar-track-color: transparent;
  scrollbar-shadow-color: transparent;
  scrollbar-arrow-color: #fff;
  scrollbar-face-color: #fff;
}

.masonry-articles__list-wrapper::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

.masonry-articles__list-wrapper::-webkit-scrollbar-button {
  height: 10px;
  background-size: 7px;
  background-position: center;
  background-repeat: no-repeat;
}

.masonry-articles__list-wrapper::-webkit-scrollbar-button:vertical:decrement {
  background-image: url("data:image/svg+xml,%3csvg height='7' width='7' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg'%3e%3cpolygon transform='rotate%280,3.5,3.5%29' points='0,6 7,6 3.5,2' style='fill:%23fff' /%3e%3c/svg%3e");
}

.masonry-articles__list-wrapper::-webkit-scrollbar-button:vertical:increment {
  background-image: url("data:image/svg+xml,%3csvg height='7' width='7' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg'%3e%3cpolygon transform='rotate%28180,3.5,3.5%29' points='0,6 7,6 3.5,2' style='fill:%23fff' /%3e%3c/svg%3e");
}

.masonry-articles__list-wrapper::-webkit-scrollbar-button:horizontal:decrement {
  background-image: url("data:image/svg+xml,%3csvg height='7' width='7' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg'%3e%3cpolygon transform='rotate%28270,3.5,3.5%29' points='0,6 7,6 3.5,2' style='fill:%23fff' /%3e%3c/svg%3e");
}

.masonry-articles__list-wrapper::-webkit-scrollbar-button:horizontal:increment {
  background-image: url("data:image/svg+xml,%3csvg height='7' width='7' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg'%3e%3cpolygon transform='rotate%2890,3.5,3.5%29' points='0,6 7,6 3.5,2' style='fill:%23fff' /%3e%3c/svg%3e");
}

.masonry-articles__list-wrapper::-webkit-scrollbar-thumb {
  background-color: #fff;
  border: 0 solid transparent;
}

.masonry-articles__list-wrapper::-webkit-scrollbar-thumb:vertical {
  border-width: 0 2px;
}

.masonry-articles__list-wrapper::-webkit-scrollbar-thumb:horizontal {
  border-width: 2px;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1em;
}

h1, .h1 {
  font-size: 3.75rem;
}

@media (max-width: 1440px) {
  h1, .h1 {
    font-size: calc(1.4rem + 2.61111vw) ;
  }
}

h2, .h2 {
  font-size: 2.8125rem;
}

@media (max-width: 1440px) {
  h2, .h2 {
    font-size: calc(1.2125rem + 1.77778vw) ;
  }
}

h3, .h3 {
  font-size: 2rem;
}

@media (max-width: 1440px) {
  h3, .h3 {
    font-size: calc(1.05rem + 1.05556vw) ;
  }
}

h4, .h4 {
  font-size: 1.625rem;
}

@media (max-width: 1440px) {
  h4, .h4 {
    font-size: calc(0.975rem + 0.72222vw) ;
  }
}

h5, .h5 {
  font-size: 1.375rem;
}

@media (max-width: 1440px) {
  h5, .h5 {
    font-size: calc(0.925rem + 0.5vw) ;
  }
}

h6, .h6 {
  font-size: 1.125rem;
}

@media (max-width: 1440px) {
  h6, .h6 {
    font-size: calc(0.875rem + 0.27778vw) ;
  }
}

.hnav-outer {
  border-bottom: 1px solid #e6e6e6;
  position: sticky;
  top: 0;
  z-index: 200;
}

.dark-theme .hnav-outer {
  border-bottom-color: #323232;
}

body.home .hnav-outer {
  border-bottom-color: transparent;
}

.hnav {
  display: flex;
  align-items: center;
  height: 3.75rem;
}

.hnav__hamburger-button {
  background: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M19.428 19.428c.31-.318.31-.869 0-1.178L11.178 10l8.25-8.25c.31-.318.318-.869 0-1.178a.864.864 0 00-1.178 0L10 8.822 1.75.571C1.441.262.881.254.572.572c-.31.318-.31.878 0 1.178L8.822 10l-8.25 8.25c-.31.309-.318.869 0 1.178.318.31.869.31 1.178 0l8.25-8.25 8.25 8.25c.318.31.869.318 1.178 0z' fill='%23323232' fill-rule='nonzero'/%3e%3c/svg%3e") center/contain no-repeat;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.25rem;
}

.hnav__hamburger-button.closed {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='%23323232' fill-rule='evenodd'%3e%3cpath d='M0 2h20v2H0zM0 9h20v2H0zM0 16h20v2H0z'/%3e%3c/g%3e%3c/svg%3e");
}

.dark-theme .hnav__hamburger-button {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M19.428 19.428c.31-.318.31-.869 0-1.178L11.178 10l8.25-8.25c.31-.318.318-.869 0-1.178a.864.864 0 00-1.178 0L10 8.822 1.75.571C1.441.262.881.254.572.572c-.31.318-.31.878 0 1.178L8.822 10l-8.25 8.25c-.31.309-.318.869 0 1.178.318.31.869.31 1.178 0l8.25-8.25 8.25 8.25c.318.31.869.318 1.178 0z' fill='%23fff' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.dark-theme .hnav__hamburger-button.closed {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='%23fff' fill-rule='evenodd'%3e%3cpath d='M0 2h20v2H0zM0 9h20v2H0zM0 16h20v2H0z'/%3e%3c/g%3e%3c/svg%3e");
}

.hnav__left {
  display: flex;
  align-items: center;
  margin-right: auto;
  height: 100%;
}

.hnav__left__parent img {
  height: 1.5625rem;
}

.hnav__left__parent img.white {
  display: none;
}

.hnav__left__parent img.black {
  display: inline;
}

.dark-theme .hnav__left__parent img.white {
  display: inline;
}

.dark-theme .hnav__left__parent img.black {
  display: none;
}

.hnav__left__sep {
  border-right: 1px solid #e6e6e6;
  margin-right: 0.78125rem;
  margin-left: 0.9375rem;
  height: 100%;
}

.dark-theme .hnav__left__sep {
  border-right-color: #323232;
}

body.home .hnav__left__sep {
  height: calc(100% - 1.875rem);
}

.hnav__left__self {
  color: #00d7be !important;
}

.hnav__right {
  display: none;
}

@media (min-width: 680px) {
  .hnav {
    height: 5rem;
  }
  .hnav__left__parent img {
    height: 2.25rem;
  }
}

@media (min-width: 1024px) {
  .hnav__hamburger-button {
    display: none;
  }
  .hnav__right {
    display: flex;
    align-items: center;
  }
  .hnav__right__menu {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .hnav__right__menu-item {
    display: inline-block;
    margin-left: 1.5625rem;
  }
  .hnav__right__menu-item:hover {
    color: #00d7be;
  }
  .hnav__right__menu-item.active {
    color: #00d7be;
  }
}

@media (min-width: 1280px) {
  .hnav__left__sep {
    margin-right: 1.5625rem;
    margin-left: 1.875rem;
  }
}

.breadcrumb-outer {
  border-bottom: 1px solid #e6e6e6;
}

.dark-theme .breadcrumb-outer {
  border-bottom-color: #323232;
}

.breadcrumb {
  font-size: 1.25rem;
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
}

@media (max-width: 1440px) {
  .breadcrumb {
    font-size: calc(0.9rem + 0.38889vw) ;
  }
}

.breadcrumb ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb li {
  display: inline-block;
}

.breadcrumb li:not(:first-child)::before {
  content: ' / ';
}

.breadcrumb li.active a {
  color: #00d7be;
}

.page-header {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.page-header__image {
  position: absolute;
  top: 0;
  left: 40px;
  right: 40px;
  height: 100%;
  background: right top / auto 71% no-repeat;
  z-index: 1;
}

.page-header__title {
  font-size: 3.75rem;
  flex: auto;
  margin-bottom: 1.25rem;
  z-index: 2;
}

@media (max-width: 1440px) {
  .page-header__title {
    font-size: calc(1.4rem + 2.61111vw) ;
  }
}

.page-header__sharer {
  margin-bottom: 1.25rem;
  margin-right: 20px;
  z-index: 3;
}

.page-header__subtext {
  font-size: 1.625rem;
  z-index: 4;
}

@media (max-width: 1440px) {
  .page-header__subtext {
    font-size: calc(0.975rem + 0.72222vw) ;
  }
}

.page-header__subtext a {
  color: #00d7be;
}

@media (max-width: 679.98px) {
  .page-header {
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
  }
  .page-header--has-image {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 2.5rem;
    padding-bottom: 1.25rem;
  }
  .page-header__subtext {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .page-header__image {
    left: 20px;
    right: 20px;
  }
}

@media (min-width: 680px) {
  .page-header {
    margin-top: 3.4375rem;
    margin-bottom: 2.8125rem;
  }
  .page-header__image {
    background-size: auto 100%;
    left: 30px;
    right: 30px;
  }
  .page-header--has-image {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3.4375rem;
    padding-bottom: 2.8125rem;
  }
  .page-header__sharer {
    align-self: flex-end;
    flex: 0 0 auto;
  }
  .page-header__subtext {
    font-size: 1.625rem;
    line-height: 1.31;
    flex-basis: 50%;
    margin-top: 2.8125rem;
    margin-bottom: 4.0625rem;
    margin-right: 50%;
    order: 3;
  }
}

@media (min-width: 680px) and (max-width: 1440px) {
  .page-header__subtext {
    font-size: calc(0.975rem + 0.72222vw) ;
  }
}

@media (min-width: 680px) {
  .page-header__subtext a {
    color: #00d7be;
  }
}

@media (min-width: 1024px) {
  .page-header__image {
    left: 40px;
    right: 40px;
  }
}

.page-footer__top {
  border-top: 1px solid #e6e6e6;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

.dark-theme .page-footer__top {
  border-top-color: #323232;
}

.page-footer__top__inner {
  display: flex;
  justify-content: space-between;
}

.page-footer__top__back {
  position: relative;
  padding-left: 1.875rem;
  font-size: 1.375rem;
}

.page-footer__top__back::before {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: center/1.25rem 1.25rem no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3e%3cg transform='rotate%28180 10 10%29' fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23323232' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.dark-theme .page-footer__top__back::before {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cg transform='rotate%28180 10 10%29' fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23fff' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
}

@media (max-width: 1440px) {
  .page-footer__top__back {
    font-size: calc(0.925rem + 0.5vw) ;
  }
}

.page-footer__top__b2top {
  position: relative;
  padding-right: 1.875rem;
  font-size: 1.375rem;
}

.page-footer__top__b2top::after {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: center/1.25rem 1.25rem no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3e%3cg transform='rotate%28-90 10 10%29' fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23323232' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.dark-theme .page-footer__top__b2top::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cg transform='rotate%28-90 10 10%29' fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23fff' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
}

@media (max-width: 1440px) {
  .page-footer__top__b2top {
    font-size: calc(0.925rem + 0.5vw) ;
  }
}

@media (min-width: 1024px) {
  .page-footer__top {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
}

.page-footer__middle__title {
  margin-bottom: 1.25rem;
}

.page-footer__middle__content {
  color: #828282;
}

.page-footer__middle__content a {
  color: #00d7be;
}

.page-footer__middle__content a:hover {
  color: #fff;
}

@media (max-width: 679.98px) {
  .page-footer__middle__cell {
    border-top: 1px solid #e6e6e6;
    padding: 2.5rem 20px;
  }
  .dark-theme .page-footer__middle__cell {
    border-top-color: #323232;
  }
}

@media (min-width: 680px) and (max-width: 1023.98px) {
  .page-footer__middle {
    display: flex;
    flex-wrap: wrap;
  }
  .page-footer__middle__cell {
    border-top: 1px solid #e6e6e6;
    flex: 1 0 50%;
    padding: 30px;
  }
  .dark-theme .page-footer__middle__cell {
    border-top-color: #323232;
  }
  .page-footer__middle__cell--a {
    padding-right: 15px;
  }
  .page-footer__middle__cell--b {
    padding-left: 15px;
  }
}

@media (min-width: 1024px) {
  .page-footer__middle {
    border-top: 1px solid #e6e6e6;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
  }
  .dark-theme .page-footer__middle {
    border-top-color: #323232;
  }
  .page-footer__middle__cell {
    flex: 1 0 33%;
    padding: 30px 15px;
  }
  .page-footer__middle__content {
    max-width: 19em;
  }
}

@media (min-width: 1280px) {
  .page-footer__middle {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
  }
  .page-footer__middle__cell {
    max-width: 466.66667px;
    padding: 40px 20px;
  }
}

.page-footer__bottom {
  font-size: 0.8125rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  color: #828282;
}

.featured-article {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 680px) {
  .featured-article {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .featured-article {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.featured-article__image {
  display: none;
}

.featured-article:hover .article-cell__button::after {
  animation: block-arrow-animation 1.8s infinite linear;
}

.featured-article__inner {
  position: relative;
}

@media (max-width: 679.98px) {
  .featured-article__image {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    background: center / cover no-repeat;
    z-index: 5;
  }
}

@media (min-width: 680px) {
  .featured-article__inner {
    display: flex;
  }
  .featured-article__image {
    display: block;
    flex: auto;
    background: center / contain no-repeat;
  }
  .featured-article__right {
    width: calc( (100% - 30px * 1) / 2);
  }
  .featured-article__right .article-cell__image {
    display: none;
  }
}

@media (min-width: 1280px) {
  .featured-article__right {
    width: calc( (100% - 40px * 2) / 3);
  }
}

.article-cell {
  display: flex;
  background-color: #f8f8f8;
  color: #323232;
  position: relative;
}

.article-cell::before {
  content: '';
  display: inline-block;
  vertical-align: top;
  width: 0;
  padding-bottom: 100%;
}

.dark-theme .article-cell {
  background-color: #323232;
  color: #fff;
}

.article-cell__content > * {
  position: relative;
  z-index: 10;
}

.article-cell__content {
  padding: 0.9375rem 1.25rem 5rem 1.25rem;
}

.article-cell__date {
  color: #828282;
  margin-bottom: 0.625rem;
}

.article-cell__title {
  font-size: 1.625rem;
}

@media (max-width: 1440px) {
  .article-cell__title {
    font-size: calc(0.975rem + 0.72222vw) ;
  }
}

.article-cell__desc {
  font-size: 0.8125rem;
  margin-top: 0.625rem;
}

.article-cell__image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: center / cover no-repeat;
  z-index: 5;
}

.article-cell__button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 3.75rem;
  height: 3.75rem;
  background-color: #00d7be;
  overflow: hidden;
}

.article-cell__button::after {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: center/1.25rem 1.25rem no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23323232' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.article-cell:hover .article-cell__button::after {
  animation: block-arrow-animation 1.8s infinite linear;
}

.article-cell--has-image .article-cell__content {
  padding-bottom: calc(50% + 1.25rem);
}

.article-cell--cover {
  color: #fff;
}

.article-cell--cover .article-cell__date {
  display: none;
}

.article-cell--cover .article-cell__image {
  height: 100%;
}

.article-cell--larger-text .article-cell__title {
  font-size: 2.8125rem;
}

@media (max-width: 1440px) {
  .article-cell--larger-text .article-cell__title {
    font-size: calc(1.2125rem + 1.77778vw) ;
  }
}

@media (min-width: 680px) {
  .article-cell--larger-text .article-cell__title {
    font-size: 2rem;
    padding-top: 0.3125rem;
  }
}

@media (min-width: 680px) and (max-width: 1440px) {
  .article-cell--larger-text .article-cell__title {
    font-size: calc(1.05rem + 1.05556vw) ;
  }
}

.article-cell--larger-text .article-cell__desc {
  font-size: 1.25rem;
  margin-top: 0.9375rem;
}

@media (max-width: 1440px) {
  .article-cell--larger-text .article-cell__desc {
    font-size: calc(0.9rem + 0.38889vw) ;
  }
}

.article-cell:hover .article-cell__title {
  color: #00d7be;
}

@media (min-width: 1280px) {
  .article-cell__content {
    padding: 1.25rem 1.5625rem 5.5rem 1.5625rem;
  }
  .article-cell__date {
    margin-bottom: 0.3125rem;
  }
  .article-cell__title {
    line-height: 1.31;
  }
  .article-cell__desc {
    margin-top: 0.9375rem;
  }
  .article-cell__button {
    width: 4.25rem;
    height: 4.25rem;
  }
  .article-cell--larger-text .article-cell__desc {
    margin-top: 1.5625rem;
  }
}

.sns-list {
  line-height: 0;
}

.sns-list__sns {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #00d7be;
}

.sns-list__sns:not(:first-child) {
  margin-left: 0.625rem;
}

.sns-list__sns::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: center/1.25rem no-repeat;
}

.sns-list__sns.fb::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 5h-1.7c-.656 0-.8.398-.8 1v2h3l-.598 3H11.5v7H8.3v-7H5.5V8h2.8V5.5C8.3 3.344 9.443 2 12 2h2v3z' fill='%23323232' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.sns-list__sns.ig::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.71 1.054c3.273.15 5.088 1.97 5.235 5.234.038.85.05 1.187.053 2.946v1.53c-.002 1.758-.015 2.097-.053 2.945-.148 3.272-1.97 5.085-5.234 5.236-.846.038-1.185.05-2.92.053H9.234c-1.759-.002-2.096-.015-2.946-.053-3.27-.15-5.085-1.966-5.235-5.236-.04-.885-.053-1.215-.054-3.183V9.471c.001-1.967.013-2.296.054-3.183.15-3.27 1.966-5.085 5.235-5.234C7.175 1.012 7.504 1 9.471.999h1.075c1.95.002 2.281.014 3.165.055zM11 2.622H9.22c-1.702.002-2.022.013-2.857.05-2.442.113-3.578 1.268-3.69 3.69-.043.95-.053 1.234-.053 3.637s.01 2.687.053 3.637c.112 2.416 1.243 3.578 3.69 3.69.834.037 1.155.05 2.857.052h1.557c1.704-.002 2.024-.014 2.859-.052 2.442-.11 3.577-1.27 3.69-3.69.039-.873.05-1.184.051-3.1V9.461c-.001-1.915-.012-2.225-.051-3.098-.113-2.421-1.251-3.578-3.69-3.69-.797-.037-1.126-.048-2.635-.051zM10 5.378a4.622 4.622 0 110 9.243 4.622 4.622 0 010-9.243zm0 1.621a3 3 0 100 6 3 3 0 000-6zm4.804-2.884a1.08 1.08 0 110 2.16 1.08 1.08 0 010-2.16z' fill='%23323232' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.sns-list__sns.linkedin::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.283 17.833H3.117v-9.5h3.166v9.5zM4.7 7a2 2 0 110-4 2 2 0 010 4zm12.667 10.833h-3.164v-4.53c0-2.978-3.17-2.726-3.17 0v4.53H7.867v-9.5h3.166v1.73c1.381-2.558 6.334-2.748 6.334 2.452v5.318z' fill='%23323232' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.sns-list__sns.twitter::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M17.953 5.986C18.214 11.666 13.91 18 6.289 18 3.972 18 1.816 17.33 0 16.185a8.35 8.35 0 006.075-1.672 4.105 4.105 0 01-3.835-2.805 4.18 4.18 0 001.855-.07c-1.973-.39-3.336-2.14-3.292-4.01a4.144 4.144 0 001.859.504 4.006 4.006 0 01-1.27-5.392 11.71 11.71 0 008.457 4.22C9.251 4.433 11.198 2 13.85 2c1.178 0 2.245.49 2.994 1.275a8.265 8.265 0 002.606-.98 4.067 4.067 0 01-1.805 2.235A8.289 8.289 0 0020 3.893a8.165 8.165 0 01-2.047 2.093z' fill='%23323232' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.sns-list__sns.yt::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16.344 2.662C19.597 2.896 19.974 4.567 20 10c-.026 5.444-.407 7.104-3.656 7.338-3.003.216-9.691.216-12.69 0C.403 17.104.024 15.433 0 10c.024-5.444.407-7.104 3.654-7.338 2.999-.216 9.689-.216 12.69 0zM8.095 6.719v6.562l5.715-3.287L8.095 6.72z' fill='%23323232' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.sns-list__sns.mail::after {
  background-image: url("data:image/svg+xml,%3csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 20'%3e%3cdefs%3e%3cstyle%3e.cls-1,.cls-2,.cls-4{fill:none;}.cls-2{clip-rule:evenodd;}.cls-3{clip-path:url%28%23clip-path%29;}.cls-4{stroke:%23323232;stroke-miterlimit:10;stroke-width:3px;}.cls-5{clip-path:url%28%23clip-path-2%29;}.cls-6{clip-path:url%28%23clip-path-3%29;}%3c/style%3e%3cclipPath id='clip-path'%3e%3crect class='cls-1' x='0.1' y='2.5' width='19.8' height='15' rx='1'/%3e%3c/clipPath%3e%3cclipPath id='clip-path-2'%3e%3cpath class='cls-2' d='M18.54,2.5a1,1,0,0,1,1,1,1,1,0,0,1-.3.72L13.3,10l5.94,5.78a1,1,0,0,1-.7,1.72H1.46a1,1,0,0,1-1-1,1,1,0,0,1,.3-.72L6.7,10,.76,4.22a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3Z'/%3e%3c/clipPath%3e%3cclipPath id='clip-path-3'%3e%3cpath class='cls-2' d='M7.91,11.18l-7.15-7a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3H18.54a1,1,0,0,1,1,1,1,1,0,0,1-.3.72l-7.15,7A3,3,0,0,1,7.91,11.18Z'/%3e%3c/clipPath%3e%3c/defs%3e%3cg class='cls-3'%3e%3crect class='cls-4' x='0.1' y='2.5' width='19.8' height='15' rx='1'/%3e%3c/g%3e%3cg class='cls-5'%3e%3cpath class='cls-4' d='M18.54,2.5a1,1,0,0,1,1,1,1,1,0,0,1-.3.72L13.3,10l5.94,5.78a1,1,0,0,1-.7,1.72H1.46a1,1,0,0,1-1-1,1,1,0,0,1,.3-.72L6.7,10,.76,4.22a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3Z'/%3e%3c/g%3e%3cg class='cls-6'%3e%3cpath class='cls-4' d='M7.91,11.18l-7.15-7a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3H18.54a1,1,0,0,1,1,1,1,1,0,0,1-.3.72l-7.15,7A3,3,0,0,1,7.91,11.18Z'/%3e%3c/g%3e%3c/svg%3e");
}

.sns-list__sns:hover {
  background-color: #000;
}

.sns-list__sns:hover.fb::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 5h-1.7c-.656 0-.8.398-.8 1v2h3l-.598 3H11.5v7H8.3v-7H5.5V8h2.8V5.5C8.3 3.344 9.443 2 12 2h2v3z' fill='%2300D7BE' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.sns-list__sns:hover.ig::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.71 1.054c3.273.15 5.088 1.97 5.235 5.234.038.85.05 1.187.053 2.946v1.53c-.002 1.758-.015 2.097-.053 2.945-.148 3.272-1.97 5.085-5.234 5.236-.846.038-1.185.05-2.92.053H9.234c-1.759-.002-2.096-.015-2.946-.053-3.27-.15-5.085-1.966-5.235-5.236-.04-.885-.053-1.215-.054-3.183V9.471c.001-1.967.013-2.296.054-3.183.15-3.27 1.966-5.085 5.235-5.234C7.175 1.012 7.504 1 9.471.999h1.075c1.95.002 2.281.014 3.165.055zM11 2.622H9.22c-1.702.002-2.022.013-2.857.05-2.442.113-3.578 1.268-3.69 3.69-.043.95-.053 1.234-.053 3.637s.01 2.687.053 3.637c.112 2.416 1.243 3.578 3.69 3.69.834.037 1.155.05 2.857.052h1.557c1.704-.002 2.024-.014 2.859-.052 2.442-.11 3.577-1.27 3.69-3.69.039-.873.05-1.184.051-3.1V9.461c-.001-1.915-.012-2.225-.051-3.098-.113-2.421-1.251-3.578-3.69-3.69-.797-.037-1.126-.048-2.635-.051zM10 5.378a4.622 4.622 0 110 9.243 4.622 4.622 0 010-9.243zm0 1.621a3 3 0 100 6 3 3 0 000-6zm4.804-2.884a1.08 1.08 0 110 2.16 1.08 1.08 0 010-2.16z' fill='%2300D7BE' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.sns-list__sns:hover.linkedin::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.283 17.833H3.117v-9.5h3.166v9.5zM4.7 7a2 2 0 110-4 2 2 0 010 4zm12.667 10.833h-3.164v-4.53c0-2.978-3.17-2.726-3.17 0v4.53H7.867v-9.5h3.166v1.73c1.381-2.558 6.334-2.748 6.334 2.452v5.318z' fill='%2300D7BE' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.sns-list__sns:hover.twitter::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M17.953 5.986C18.214 11.666 13.91 18 6.289 18 3.972 18 1.816 17.33 0 16.185a8.35 8.35 0 006.075-1.672 4.105 4.105 0 01-3.835-2.805 4.18 4.18 0 001.855-.07c-1.973-.39-3.336-2.14-3.292-4.01a4.144 4.144 0 001.859.504 4.006 4.006 0 01-1.27-5.392 11.71 11.71 0 008.457 4.22C9.251 4.433 11.198 2 13.85 2c1.178 0 2.245.49 2.994 1.275a8.265 8.265 0 002.606-.98 4.067 4.067 0 01-1.805 2.235A8.289 8.289 0 0020 3.893a8.165 8.165 0 01-2.047 2.093z' fill='%2300D7BE' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.sns-list__sns:hover.yt::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16.344 2.662C19.597 2.896 19.974 4.567 20 10c-.026 5.444-.407 7.104-3.656 7.338-3.003.216-9.691.216-12.69 0C.403 17.104.024 15.433 0 10c.024-5.444.407-7.104 3.654-7.338 2.999-.216 9.689-.216 12.69 0zM8.095 6.719v6.562l5.715-3.287L8.095 6.72z' fill='%2300D7BE' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.sns-list__sns:hover.mail::after {
  background-image: url("data:image/svg+xml,%3csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 20'%3e%3cdefs%3e%3cstyle%3e.cls-1,.cls-2,.cls-4{fill:none;}.cls-2{clip-rule:evenodd;}.cls-3{clip-path:url%28%23clip-path%29;}.cls-4{stroke:%2300d7be;stroke-miterlimit:10;stroke-width:3px;}.cls-5{clip-path:url%28%23clip-path-2%29;}.cls-6{clip-path:url%28%23clip-path-3%29;}%3c/style%3e%3cclipPath id='clip-path'%3e%3crect class='cls-1' x='0.1' y='2.5' width='19.8' height='15' rx='1'/%3e%3c/clipPath%3e%3cclipPath id='clip-path-2'%3e%3cpath class='cls-2' d='M18.54,2.5a1,1,0,0,1,1,1,1,1,0,0,1-.3.72L13.3,10l5.94,5.78a1,1,0,0,1-.7,1.72H1.46a1,1,0,0,1-1-1,1,1,0,0,1,.3-.72L6.7,10,.76,4.22a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3Z'/%3e%3c/clipPath%3e%3cclipPath id='clip-path-3'%3e%3cpath class='cls-2' d='M7.91,11.18l-7.15-7a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3H18.54a1,1,0,0,1,1,1,1,1,0,0,1-.3.72l-7.15,7A3,3,0,0,1,7.91,11.18Z'/%3e%3c/clipPath%3e%3c/defs%3e%3cg class='cls-3'%3e%3crect class='cls-4' x='0.1' y='2.5' width='19.8' height='15' rx='1'/%3e%3c/g%3e%3cg class='cls-5'%3e%3cpath class='cls-4' d='M18.54,2.5a1,1,0,0,1,1,1,1,1,0,0,1-.3.72L13.3,10l5.94,5.78a1,1,0,0,1-.7,1.72H1.46a1,1,0,0,1-1-1,1,1,0,0,1,.3-.72L6.7,10,.76,4.22a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3Z'/%3e%3c/g%3e%3cg class='cls-6'%3e%3cpath class='cls-4' d='M7.91,11.18l-7.15-7a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3H18.54a1,1,0,0,1,1,1,1,1,0,0,1-.3.72l-7.15,7A3,3,0,0,1,7.91,11.18Z'/%3e%3c/g%3e%3c/svg%3e");
}

.dark-theme .sns-list--darkable .sns-list__sns {
  background-color: #323232;
}

.dark-theme .sns-list--darkable .sns-list__sns.fb::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 5h-1.7c-.656 0-.8.398-.8 1v2h3l-.598 3H11.5v7H8.3v-7H5.5V8h2.8V5.5C8.3 3.344 9.443 2 12 2h2v3z' fill='%2300D7BE' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.dark-theme .sns-list--darkable .sns-list__sns.ig::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.71 1.054c3.273.15 5.088 1.97 5.235 5.234.038.85.05 1.187.053 2.946v1.53c-.002 1.758-.015 2.097-.053 2.945-.148 3.272-1.97 5.085-5.234 5.236-.846.038-1.185.05-2.92.053H9.234c-1.759-.002-2.096-.015-2.946-.053-3.27-.15-5.085-1.966-5.235-5.236-.04-.885-.053-1.215-.054-3.183V9.471c.001-1.967.013-2.296.054-3.183.15-3.27 1.966-5.085 5.235-5.234C7.175 1.012 7.504 1 9.471.999h1.075c1.95.002 2.281.014 3.165.055zM11 2.622H9.22c-1.702.002-2.022.013-2.857.05-2.442.113-3.578 1.268-3.69 3.69-.043.95-.053 1.234-.053 3.637s.01 2.687.053 3.637c.112 2.416 1.243 3.578 3.69 3.69.834.037 1.155.05 2.857.052h1.557c1.704-.002 2.024-.014 2.859-.052 2.442-.11 3.577-1.27 3.69-3.69.039-.873.05-1.184.051-3.1V9.461c-.001-1.915-.012-2.225-.051-3.098-.113-2.421-1.251-3.578-3.69-3.69-.797-.037-1.126-.048-2.635-.051zM10 5.378a4.622 4.622 0 110 9.243 4.622 4.622 0 010-9.243zm0 1.621a3 3 0 100 6 3 3 0 000-6zm4.804-2.884a1.08 1.08 0 110 2.16 1.08 1.08 0 010-2.16z' fill='%2300D7BE' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.dark-theme .sns-list--darkable .sns-list__sns.linkedin::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.283 17.833H3.117v-9.5h3.166v9.5zM4.7 7a2 2 0 110-4 2 2 0 010 4zm12.667 10.833h-3.164v-4.53c0-2.978-3.17-2.726-3.17 0v4.53H7.867v-9.5h3.166v1.73c1.381-2.558 6.334-2.748 6.334 2.452v5.318z' fill='%2300D7BE' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.dark-theme .sns-list--darkable .sns-list__sns.twitter::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M17.953 5.986C18.214 11.666 13.91 18 6.289 18 3.972 18 1.816 17.33 0 16.185a8.35 8.35 0 006.075-1.672 4.105 4.105 0 01-3.835-2.805 4.18 4.18 0 001.855-.07c-1.973-.39-3.336-2.14-3.292-4.01a4.144 4.144 0 001.859.504 4.006 4.006 0 01-1.27-5.392 11.71 11.71 0 008.457 4.22C9.251 4.433 11.198 2 13.85 2c1.178 0 2.245.49 2.994 1.275a8.265 8.265 0 002.606-.98 4.067 4.067 0 01-1.805 2.235A8.289 8.289 0 0020 3.893a8.165 8.165 0 01-2.047 2.093z' fill='%2300D7BE' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.dark-theme .sns-list--darkable .sns-list__sns.yt::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16.344 2.662C19.597 2.896 19.974 4.567 20 10c-.026 5.444-.407 7.104-3.656 7.338-3.003.216-9.691.216-12.69 0C.403 17.104.024 15.433 0 10c.024-5.444.407-7.104 3.654-7.338 2.999-.216 9.689-.216 12.69 0zM8.095 6.719v6.562l5.715-3.287L8.095 6.72z' fill='%2300D7BE' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.dark-theme .sns-list--darkable .sns-list__sns.mail::after {
  background-image: url("data:image/svg+xml,%3csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 20'%3e%3cdefs%3e%3cstyle%3e.cls-1,.cls-2,.cls-4{fill:none;}.cls-2{clip-rule:evenodd;}.cls-3{clip-path:url%28%23clip-path%29;}.cls-4{stroke:%23323232;stroke-miterlimit:10;stroke-width:3px;}.cls-5{clip-path:url%28%23clip-path-2%29;}.cls-6{clip-path:url%28%23clip-path-3%29;}%3c/style%3e%3cclipPath id='clip-path'%3e%3crect class='cls-1' x='0.1' y='2.5' width='19.8' height='15' rx='1'/%3e%3c/clipPath%3e%3cclipPath id='clip-path-2'%3e%3cpath class='cls-2' d='M18.54,2.5a1,1,0,0,1,1,1,1,1,0,0,1-.3.72L13.3,10l5.94,5.78a1,1,0,0,1-.7,1.72H1.46a1,1,0,0,1-1-1,1,1,0,0,1,.3-.72L6.7,10,.76,4.22a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3Z'/%3e%3c/clipPath%3e%3cclipPath id='clip-path-3'%3e%3cpath class='cls-2' d='M7.91,11.18l-7.15-7a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3H18.54a1,1,0,0,1,1,1,1,1,0,0,1-.3.72l-7.15,7A3,3,0,0,1,7.91,11.18Z'/%3e%3c/clipPath%3e%3c/defs%3e%3cg class='cls-3'%3e%3crect class='cls-4' x='0.1' y='2.5' width='19.8' height='15' rx='1'/%3e%3c/g%3e%3cg class='cls-5'%3e%3cpath class='cls-4' d='M18.54,2.5a1,1,0,0,1,1,1,1,1,0,0,1-.3.72L13.3,10l5.94,5.78a1,1,0,0,1-.7,1.72H1.46a1,1,0,0,1-1-1,1,1,0,0,1,.3-.72L6.7,10,.76,4.22a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3Z'/%3e%3c/g%3e%3cg class='cls-6'%3e%3cpath class='cls-4' d='M7.91,11.18l-7.15-7a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3H18.54a1,1,0,0,1,1,1,1,1,0,0,1-.3.72l-7.15,7A3,3,0,0,1,7.91,11.18Z'/%3e%3c/g%3e%3c/svg%3e");
}

.dark-theme .sns-list--darkable .sns-list__sns:hover {
  background-color: #00d7be;
}

.dark-theme .sns-list--darkable .sns-list__sns:hover.fb::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 5h-1.7c-.656 0-.8.398-.8 1v2h3l-.598 3H11.5v7H8.3v-7H5.5V8h2.8V5.5C8.3 3.344 9.443 2 12 2h2v3z' fill='%23000000' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.dark-theme .sns-list--darkable .sns-list__sns:hover.ig::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.71 1.054c3.273.15 5.088 1.97 5.235 5.234.038.85.05 1.187.053 2.946v1.53c-.002 1.758-.015 2.097-.053 2.945-.148 3.272-1.97 5.085-5.234 5.236-.846.038-1.185.05-2.92.053H9.234c-1.759-.002-2.096-.015-2.946-.053-3.27-.15-5.085-1.966-5.235-5.236-.04-.885-.053-1.215-.054-3.183V9.471c.001-1.967.013-2.296.054-3.183.15-3.27 1.966-5.085 5.235-5.234C7.175 1.012 7.504 1 9.471.999h1.075c1.95.002 2.281.014 3.165.055zM11 2.622H9.22c-1.702.002-2.022.013-2.857.05-2.442.113-3.578 1.268-3.69 3.69-.043.95-.053 1.234-.053 3.637s.01 2.687.053 3.637c.112 2.416 1.243 3.578 3.69 3.69.834.037 1.155.05 2.857.052h1.557c1.704-.002 2.024-.014 2.859-.052 2.442-.11 3.577-1.27 3.69-3.69.039-.873.05-1.184.051-3.1V9.461c-.001-1.915-.012-2.225-.051-3.098-.113-2.421-1.251-3.578-3.69-3.69-.797-.037-1.126-.048-2.635-.051zM10 5.378a4.622 4.622 0 110 9.243 4.622 4.622 0 010-9.243zm0 1.621a3 3 0 100 6 3 3 0 000-6zm4.804-2.884a1.08 1.08 0 110 2.16 1.08 1.08 0 010-2.16z' fill='%23000000' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.dark-theme .sns-list--darkable .sns-list__sns:hover.linkedin::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.283 17.833H3.117v-9.5h3.166v9.5zM4.7 7a2 2 0 110-4 2 2 0 010 4zm12.667 10.833h-3.164v-4.53c0-2.978-3.17-2.726-3.17 0v4.53H7.867v-9.5h3.166v1.73c1.381-2.558 6.334-2.748 6.334 2.452v5.318z' fill='%23000000' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.dark-theme .sns-list--darkable .sns-list__sns:hover.twitter::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M17.953 5.986C18.214 11.666 13.91 18 6.289 18 3.972 18 1.816 17.33 0 16.185a8.35 8.35 0 006.075-1.672 4.105 4.105 0 01-3.835-2.805 4.18 4.18 0 001.855-.07c-1.973-.39-3.336-2.14-3.292-4.01a4.144 4.144 0 001.859.504 4.006 4.006 0 01-1.27-5.392 11.71 11.71 0 008.457 4.22C9.251 4.433 11.198 2 13.85 2c1.178 0 2.245.49 2.994 1.275a8.265 8.265 0 002.606-.98 4.067 4.067 0 01-1.805 2.235A8.289 8.289 0 0020 3.893a8.165 8.165 0 01-2.047 2.093z' fill='%23000000' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.dark-theme .sns-list--darkable .sns-list__sns:hover.yt::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16.344 2.662C19.597 2.896 19.974 4.567 20 10c-.026 5.444-.407 7.104-3.656 7.338-3.003.216-9.691.216-12.69 0C.403 17.104.024 15.433 0 10c.024-5.444.407-7.104 3.654-7.338 2.999-.216 9.689-.216 12.69 0zM8.095 6.719v6.562l5.715-3.287L8.095 6.72z' fill='%23000000' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.dark-theme .sns-list--darkable .sns-list__sns:hover.mail::after {
  background-image: url("data:image/svg+xml,%3csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 20'%3e%3cdefs%3e%3cstyle%3e.cls-1,.cls-2,.cls-4{fill:none;}.cls-2{clip-rule:evenodd;}.cls-3{clip-path:url%28%23clip-path%29;}.cls-4{stroke:%2300d7be;stroke-miterlimit:10;stroke-width:3px;}.cls-5{clip-path:url%28%23clip-path-2%29;}.cls-6{clip-path:url%28%23clip-path-3%29;}%3c/style%3e%3cclipPath id='clip-path'%3e%3crect class='cls-1' x='0.1' y='2.5' width='19.8' height='15' rx='1'/%3e%3c/clipPath%3e%3cclipPath id='clip-path-2'%3e%3cpath class='cls-2' d='M18.54,2.5a1,1,0,0,1,1,1,1,1,0,0,1-.3.72L13.3,10l5.94,5.78a1,1,0,0,1-.7,1.72H1.46a1,1,0,0,1-1-1,1,1,0,0,1,.3-.72L6.7,10,.76,4.22a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3Z'/%3e%3c/clipPath%3e%3cclipPath id='clip-path-3'%3e%3cpath class='cls-2' d='M7.91,11.18l-7.15-7a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3H18.54a1,1,0,0,1,1,1,1,1,0,0,1-.3.72l-7.15,7A3,3,0,0,1,7.91,11.18Z'/%3e%3c/clipPath%3e%3c/defs%3e%3cg class='cls-3'%3e%3crect class='cls-4' x='0.1' y='2.5' width='19.8' height='15' rx='1'/%3e%3c/g%3e%3cg class='cls-5'%3e%3cpath class='cls-4' d='M18.54,2.5a1,1,0,0,1,1,1,1,1,0,0,1-.3.72L13.3,10l5.94,5.78a1,1,0,0,1-.7,1.72H1.46a1,1,0,0,1-1-1,1,1,0,0,1,.3-.72L6.7,10,.76,4.22a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3Z'/%3e%3c/g%3e%3cg class='cls-6'%3e%3cpath class='cls-4' d='M7.91,11.18l-7.15-7a1,1,0,0,1,0-1.42,1,1,0,0,1,.72-.3H18.54a1,1,0,0,1,1,1,1,1,0,0,1-.3.72l-7.15,7A3,3,0,0,1,7.91,11.18Z'/%3e%3c/g%3e%3c/svg%3e");
}

@media (min-width: 1280px) {
  .sns-list__sns {
    width: 3rem;
    height: 3rem;
  }
}

.articles-list {
  margin-top: 20px;
  margin-bottom: 40px;
  padding-bottom: 0.1px;
}

@media (min-width: 680px) {
  .articles-list {
    margin-top: 30px;
  }
}

@media (min-width: 1280px) {
  .articles-list {
    margin-top: 40px;
  }
}

@media (min-width: 680px) {
  .articles-list {
    margin-bottom: 60px;
  }
}

@media (min-width: 1280px) {
  .articles-list {
    margin-bottom: 80px;
  }
}

.articles-list__cells {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: -20px;
}

@media (min-width: 680px) {
  .articles-list__cells {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 1280px) {
  .articles-list__cells {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media (min-width: 680px) {
  .articles-list__cells {
    margin-bottom: -30px;
  }
}

@media (min-width: 1280px) {
  .articles-list__cells {
    margin-bottom: -40px;
  }
}

.articles-list__cell {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}

@media (min-width: 680px) {
  .articles-list__cell {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1280px) {
  .articles-list__cell {
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 680px) {
  .articles-list__cell {
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .articles-list__cell {
    margin-bottom: 40px;
  }
}

.articles-list__cell .article-cell {
  height: 100%;
}

@media (max-width: 679.98px) {
  .articles-list__cells > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 680px) {
  .articles-list__cells > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1280px) {
  .articles-list__cells > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

.article-header {
  margin-top: 2.1875rem;
  margin-bottom: 2.1875rem;
}

.article-header + * {
  margin-top: 0 !important;
}

.article-header__date {
  color: #828282;
  margin-bottom: 1.25rem;
}

.article-header__title {
  font-size: 2.8125rem;
}

@media (max-width: 1440px) {
  .article-header__title {
    font-size: calc(1.2125rem + 1.77778vw) ;
  }
}

.article-header__sharers {
  margin-top: 1.875rem;
}

.article-text-section {
  font-size: 1.25rem;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

@media (max-width: 1440px) {
  .article-text-section {
    font-size: calc(0.9rem + 0.38889vw) ;
  }
}

.article-text-section *:first-child {
  margin-top: 0 !important;
}

.article-text-section *:last-child {
  margin-bottom: 0 !important;
}

.article-text-section a {
  color: #00d7be;
}

.article-text-section h1, .article-text-section h2, .article-text-section h3, .article-text-section h4, .article-text-section h5, .article-text-section h6 {
  line-height: 1.2;
  margin-top: 3.75rem;
  margin-bottom: 1.25rem;
}

.article-text-section h4, .article-text-section h5, .article-text-section h6 {
  font-weight: bold;
}

.article-text-section h6 {
  font-size: 1.25rem;
}

@media (max-width: 1440px) {
  .article-text-section h6 {
    font-size: calc(0.9rem + 0.38889vw) ;
  }
}

@media (min-width: 1024px) {
  .article-text-section h1, .article-text-section h2, .article-text-section h3 {
    margin-top: 5rem;
  }
}

.image-module {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.image-module img {
  width: 100%;
}

.image-module figcaption {
  text-align: center;
  margin-top: 0.625rem;
}

.image-module--fw {
  max-width: none !important;
  padding-left: 0;
  padding-right: 0;
}

.image-slider {
  margin-top: 3.75rem;
  margin-bottom: 5rem;
  overflow-x: hidden;
}

.image-slider img {
  width: 100%;
}

.image-slider__container {
  position: relative;
}

.image-slider__arrow {
  display: block;
  position: absolute;
  top: 47%;
  transform: translate(0, -50%);
  background-color: #00d7be;
  width: 3.75rem;
  height: 3.75rem;
  z-index: 10;
}

.image-slider__arrow::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.image-slider__arrow--prev::after {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: center/1.25rem 1.25rem no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3e%3cg transform='rotate%28180 10 10%29' fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23323232' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
}

.image-slider__arrow--next {
  right: 40px;
}

.image-slider__arrow--next::after {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: center/1.25rem 1.25rem no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23323232' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
}

@media (max-width: 679.98px) {
  .image-slider__arrow {
    display: none;
  }
}

.image-slider__center-frame {
  position: relative;
  white-space: nowrap;
  transition: transform .6s;
}

.image-slider__slide {
  display: inline-block;
  margin: 0;
  width: calc(100% - 20px);
  transition: opacity .4s;
  text-align: center;
}

.image-slider__slide:not(:first-child) {
  margin-left: 20px;
}

@media (min-width: 680px) {
  .image-slider__slide:not(:first-child) {
    margin-left: 30px;
  }
}

@media (min-width: 1280px) {
  .image-slider__slide:not(:first-child) {
    margin-left: 40px;
  }
}

.image-slider__slide:not(.active) {
  opacity: 0.25;
}

.image-slider__slide figcaption {
  font-size: 0.9375rem;
  margin-top: 0.625rem;
}

@media (max-width: 1440px) {
  .image-slider__slide figcaption {
    font-size: calc(0.8375rem + 0.11111vw) ;
  }
}

@media (min-width: 680px) {
  .image-slider__arrow--next {
    right: 90px;
  }
  .image-slider__slide {
    width: calc(100% - 60px);
  }
}

@media (min-width: 1024px) {
  .image-slider__arrow--next {
    right: 30px;
  }
  .image-slider__slide {
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .image-slider__arrow {
    width: 4.25rem;
    height: 4.25rem;
  }
  .image-slider__arrow--next {
    right: 40px;
  }
}

.related-links {
  margin-top: 2.5rem;
  margin-bottom: 3.75rem;
}

.related-links .row {
  margin-top: -1.25rem;
  padding: 0.1px;
}

.related-links__title {
  margin-bottom: 2.5rem;
}

.related-links__link {
  background-color: #f8f8f8;
  color: #323232;
  position: relative;
  display: flex;
  margin-top: 1.25rem;
  min-height: 3.75rem;
}

.dark-theme .related-links__link {
  background-color: #323232;
  color: #fff;
}

.related-links__link:hover {
  background-color: #00d7be;
  color: #323232;
}

.related-links__link:hover .related-links__link__button::after {
  animation: block-arrow-animation 1.8s infinite linear;
}

.related-links__link__text {
  padding: 0.5em 0.9375rem;
  flex-grow: 1;
  align-self: center;
}

.related-links__link__button {
  position: relative;
  width: 3.75rem;
  background: #00d7be;
  overflow: hidden;
}

.related-links__link__button::after {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: center/1.25rem 1.25rem no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23323232' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 1024px) {
  .related-links {
    margin-bottom: 5rem;
  }
  .related-links .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
}

@media (min-width: 1024px) and (min-width: 680px) {
  .related-links .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 1024px) and (min-width: 1280px) {
  .related-links .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media (min-width: 1024px) {
  .related-links .col {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1024px) and (min-width: 680px) {
  .related-links .col {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1024px) and (min-width: 1280px) {
  .related-links .col {
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 1280px) {
  .related-links {
    font-size: 1.375rem;
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .related-links {
    font-size: calc(0.925rem + 0.5vw) ;
  }
}

@media (min-width: 1280px) {
  .related-links__link {
    min-height: 4.25rem;
  }
  .related-links__link__text {
    padding: 0.5em 1.25rem;
  }
  .related-links__link__button {
    width: 4.25rem;
  }
}

.main--mb {
  margin-bottom: 5rem;
}

.dark-theme {
  background-color: #1e1e1e;
  color: #fff;
}

.seo-content {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background: right bottom / auto 56% no-repeat;
}

.seo-content__title {
  font-size: 1.625rem;
  margin-bottom: 1.25rem;
}

.seo-content--has-image {
  padding-bottom: 7.5rem;
}

@media (min-width: 680px) {
  .seo-content {
    background-size: auto 100%;
  }
  .seo-content--has-image .seo-content__content {
    width: 48%;
    max-width: 33em;
  }
}

.hr__inner {
  border-bottom: 1px solid #e6e6e6;
}

.dark-theme .hr__inner {
  border-bottom-color: #323232;
}

.hr--fw {
  max-width: none !important;
  padding-left: 0;
  padding-right: 0;
}

@keyframes masonryArrow {
  0% {
    transform: translate(-50%, -50%);
  }
  7% {
    transform: translate(150%, -50%);
  }
  7.001% {
    transform: translate(-150%, -50%);
    opacity: 0;
  }
  14%, 100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.masonry__cell {
  background-color: #f8f8f8;
  color: #323232;
  transition: box-shadow 0.4s;
}

.dark-theme .masonry__cell {
  background-color: #323232;
  color: #fff;
}

.masonry__cell:hover {
  box-shadow: 0 30px 50px 0 rgba(0, 0, 0, 0.75);
}

@media (max-width: 679.98px) {
  .masonry {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
    margin-bottom: 3.75rem;
  }
  .masonry__cell:not(:first-child) {
    margin-top: 20px;
  }
  .masonry__cell--a1 {
    order: 1;
  }
  .masonry__cell--b1 {
    order: 2;
  }
  .masonry__cell--c1 {
    order: 3;
  }
  .masonry__cell--b2 {
    order: 4;
  }
  .masonry__cell--c2 {
    order: 5;
  }
  .masonry__cell--a2 {
    order: 6;
  }
  .masonry__cell--c3 {
    order: 7;
  }
  .masonry__cell--b3 {
    order: 8;
  }
  .masonry__cell--c4 {
    order: 9;
  }
}

@media (min-width: 680px) and (max-width: 1023.98px) {
  .masonry {
    display: -ms-grid;
    -ms-grid-columns: 1fr 30px 1fr;
    -ms-grid-rows: 1fr 30px 0.2fr 0.8fr 30px 1.3fr 30px 0.5fr 30px 0.5fr;
    display: grid;
    grid-template-columns: 1fr 30px 1fr;
    grid-template-rows: 1fr 30px 0.2fr 0.8fr 30px 1.3fr 30px 0.5fr 30px 0.5fr;
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .masonry__cell > *:only-child,
  .masonry__cell > [sc-part-of="placeholder rendering"]:nth-child(3):nth-last-child(3),
  .masonry__cell > [sc-part-of="placeholder"]:nth-child(3):nth-last-child(2) {
    height: 100%;
  }
  .masonry__cell--c1 {
    margin-top: 30px;
  }
  .masonry__cell--a1 {
    grid-row: 1/span 3;
    grid-column: 1/span 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--a2 {
    grid-row: 6/span 1;
    grid-column: 3/span 1;
    -ms-grid-row: 6;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--b1 {
    grid-row: 1/span 1;
    grid-column: 3/span 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--b2 {
    grid-row: 3/span 2;
    grid-column: 3/span 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 2;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--b3 {
    grid-row: 8/span 1;
    grid-column: 1/span 1;
    -ms-grid-row: 8;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--c1 {
    grid-row: 4/span 3;
    grid-column: 1/span 1;
    -ms-grid-row: 4;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--c2 {
    grid-row: 10/span 1;
    grid-column: 1/span 1;
    -ms-grid-row: 10;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--c3 {
    grid-row: 10/span 1;
    grid-column: 3/span 1;
    -ms-grid-row: 10;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--c4 {
    grid-row: 8/span 1;
    grid-column: 3/span 1;
    -ms-grid-row: 8;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
}

@media (min-width: 1024px) {
  .masonry {
    display: -ms-grid;
    -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
    -ms-grid-rows: 1fr 30px 1fr 30px 0.5fr 0.5fr 30px 1fr 30px 1fr;
    display: grid;
    grid-template-columns: 1fr 30px 1fr 30px 1fr;
    grid-template-rows: 1fr 30px 1fr 30px 0.5fr 0.5fr 30px 1fr 30px 1fr;
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .masonry__cell > *:only-child,
  .masonry__cell > [sc-part-of="placeholder rendering"]:nth-child(3):nth-last-child(3),
  .masonry__cell > [sc-part-of="placeholder"]:nth-child(3):nth-last-child(2) {
    height: 100%;
  }
  .masonry__cell--a2 {
    margin-top: 30px;
  }
  .masonry__cell--a1 {
    grid-row: 1/span 5;
    grid-column: 1/span 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--a2 {
    grid-row: 6/span 5;
    grid-column: 1/span 1;
    -ms-grid-row: 6;
    -ms-grid-row-span: 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--b1 {
    grid-row: 1/span 3;
    grid-column: 3/span 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--b2 {
    grid-row: 5/span 4;
    grid-column: 3/span 1;
    -ms-grid-row: 5;
    -ms-grid-row-span: 4;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--b3 {
    grid-row: 10/span 1;
    grid-column: 3/span 1;
    -ms-grid-row: 10;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--c1 {
    grid-row: 1/span 3;
    grid-column: 5/span 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 5;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--c2 {
    grid-row: 5/span 2;
    grid-column: 5/span 1;
    -ms-grid-row: 5;
    -ms-grid-row-span: 2;
    -ms-grid-column: 5;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--c3 {
    grid-row: 8/span 1;
    grid-column: 5/span 1;
    -ms-grid-row: 8;
    -ms-grid-row-span: 1;
    -ms-grid-column: 5;
    -ms-grid-column-span: 1;
  }
  .masonry__cell--c4 {
    grid-row: 10/span 1;
    grid-column: 5/span 1;
    -ms-grid-row: 10;
    -ms-grid-row-span: 1;
    -ms-grid-column: 5;
    -ms-grid-column-span: 1;
  }
}

@media (min-width: 1280px) {
  .masonry {
    -ms-grid-columns: 1fr 40px 1fr 40px 1fr;
    -ms-grid-rows: 1fr 40px 1fr 40px 0.5fr 0.5fr 40px 1fr 40px 1fr;
    grid-template-columns: 1fr 40px 1fr 40px 1fr;
    grid-template-rows: 1fr 40px 1fr 40px 0.5fr 0.5fr 40px 1fr 40px 1fr;
    margin-top: 5rem;
    margin-bottom: 7.5rem;
  }
  .masonry__cell--a2 {
    margin-top: 40px;
  }
}

.masonry-latest__content, .masonry-sqlink__content, .masonry-sqlink__supplement, .masonry-articles {
  padding: 0.9375rem 1.25rem 1.25rem 1.25rem;
}

@media (min-width: 1280px) {
  .masonry-latest__content, .masonry-sqlink__content, .masonry-sqlink__supplement, .masonry-articles {
    padding: 1.25rem 1.5625rem;
  }
}

.masonry-latest {
  position: relative;
  display: flex;
  flex-direction: column;
}

.masonry-latest__title {
  line-height: 1.11;
}

.masonry-latest__date {
  color: #828282;
  margin-top: 1.875rem;
  margin-bottom: 0.3125rem;
}

.masonry-latest__text-title {
  font-size: 1.375rem;
}

@media (max-width: 1440px) {
  .masonry-latest__text-title {
    font-size: calc(0.925rem + 0.5vw) ;
  }
}

.masonry-latest__image {
  flex-grow: 1;
  min-height: 200px;
  background: center / cover no-repeat;
}

.masonry-latest__button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 3.75rem;
  height: 3.75rem;
  background: #00d7be;
}

.masonry-latest__button::after {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: center/1.25rem 1.25rem no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23323232' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.masonry-latest:hover .masonry-latest__text-title {
  color: #00d7be;
}

.masonry-latest:hover .masonry-latest__button::after {
  animation: masonryArrow 2.4s infinite linear;
}

@media (min-width: 1280px) {
  .masonry-latest__text-title {
    padding-bottom: 1.25rem;
  }
  .masonry-latest__date {
    margin-top: 3.125rem;
    margin-bottom: 0.625rem;
  }
  .masonry-latest__button {
    width: 4.25rem;
    height: 4.25rem;
  }
}

.masonry-sqlink {
  display: flex;
  position: relative;
}

.masonry-sqlink::before {
  content: '';
  display: inline-block;
  vertical-align: top;
  width: 0;
  padding-bottom: 100%;
}

.masonry-sqlink__content {
  position: relative;
  line-height: 1.31;
  width: 100%;
  overflow: hidden;
}

.masonry-sqlink__content_title {
  position: relative;
  z-index: 2;
}

.masonry-sqlink__content_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  width: 100%;
  transition: transform 0.2s;
  background: center / cover no-repeat;
}

.masonry-sqlink__supplement {
  font-size: 0.8125rem;
  line-height: 1.39;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 64%;
}

.masonry-sqlink--half {
  display: flex;
}

.masonry-sqlink--half::before {
  content: '';
  display: inline-block;
  vertical-align: top;
  width: 0;
  padding-bottom: 45%;
}

.masonry-sqlink:hover .masonry-sqlink__content_image {
  transform: scale(1.1);
}

.masonry-sqlink:hover .masonry-sqlink__content_image:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  content: '';
  display: block;
}

.masonry-articles__title {
  line-height: 1.31;
}

.masonry-articles__list-wrapper {
  position: relative;
  margin-top: 1.5625rem;
}

.masonry-articles__list {
  list-style: none;
  margin: 0;
  padding: 0 5px 0 0;
}

.masonry-articles__list-item {
  border-bottom: 1px solid rgba(127, 127, 127, 0.13);
}

.masonry-articles__list-item__link {
  display: flex;
  position: relative;
  margin: 0.9375rem 0;
  min-height: 4.5em;
}

.masonry-articles__list-item__date {
  width: 4.6875rem;
  flex: 0 0 auto;
}

.masonry-articles__list-item__date .day,
.masonry-articles__list-item__date .month {
  position: absolute;
  left: 0;
  color: #00d7be;
}

.masonry-articles__list-item__date .day {
  font-size: 2.5rem;
  line-height: 1;
  top: -0.125rem;
  font-weight: normal;
}

.masonry-articles__list-item__date .month {
  font-size: 0.75rem;
  line-height: 1.5;
  top: 2.75rem;
  text-transform: uppercase;
  letter-spacing: 0.083em;
}

.masonry-articles__list-item__title {
  flex-grow: 1;
}

.masonry-articles__list-item__image {
  flex: 0 0 auto;
}

.masonry-articles__list-item__image img {
  margin-left: 0.9375rem;
  width: 4.6875rem;
}

.masonry-articles__list-item:hover .masonry-articles__list-item__title {
  color: #00d7be;
}

.masonry-articles__links {
  color: #00d7be;
  margin-top: 1.25rem;
}

.masonry-articles__links a {
  position: relative;
  padding-right: 1.875rem;
}

.masonry-articles__links a::after {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: center/1.25rem 1.25rem no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%2300d7be' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.masonry-articles__links a::after {
  transition: transform 100ms;
}

.masonry-articles__links a:hover::after {
  transform: translate(5px, -50%);
}

@media (min-width: 680px) {
  .masonry-articles {
    display: flex;
    flex-direction: column;
  }
  .masonry-articles__list-wrapper {
    flex-grow: 1;
    overflow: auto;
  }
  .masonry-articles__list {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.video-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

@media (min-width: 680px) {
  .video-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 1280px) {
  .video-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
  }
}

.video-row__top, .video-row__meta, .video-row__main, .video-row__bottom {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 680px) {
  .video-row__top, .video-row__meta, .video-row__main, .video-row__bottom {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1280px) {
  .video-row__top, .video-row__meta, .video-row__main, .video-row__bottom {
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.video-row__top {
  margin-bottom: 20px;
}

@media (min-width: 680px) {
  .video-row__top {
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .video-row__top {
    margin-bottom: 40px;
  }
}

.video-row__top__hr {
  border-bottom: 1px solid #e6e6e6;
}

.dark-theme .video-row__top__hr {
  border-bottom-color: #323232;
}

.video-row__bottom {
  margin-bottom: 40px;
}

@media (min-width: 680px) {
  .video-row__bottom {
    margin-bottom: 60px;
  }
}

@media (min-width: 1280px) {
  .video-row__bottom {
    margin-bottom: 80px;
  }
}

@media (max-width: 679.98px) {
  .video-row__meta {
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 680px) {
  .video-row__meta {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    margin-bottom: 0;
  }
  .video-row__main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}

@media (min-width: 1280px) {
  .video-row__top {
    margin-bottom: 40px;
  }
  .video-row__bottom {
    margin-bottom: 80px;
  }
}

.video-meta__date {
  font-size: 2rem;
  color: #828282;
}

@media (max-width: 1440px) {
  .video-meta__date {
    font-size: calc(1.05rem + 1.05556vw) ;
  }
}

.video-meta__title {
  font-size: 2rem;
}

@media (max-width: 1440px) {
  .video-meta__title {
    font-size: calc(1.05rem + 1.05556vw) ;
  }
}

.video-meta__avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: 1.25rem;
}

.video-meta__supplement {
  color: #828282;
  margin-top: 0.9375rem;
}

@media (min-width: 1280px) {
  .video-meta__date, .video-meta__title {
    line-height: 1.19;
  }
  .video-meta__avatar {
    width: 150px;
    height: 150px;
  }
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: -20px;
  list-style: none;
  padding: 0;
  margin-top: 0;
}

@media (min-width: 680px) {
  .video-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 1280px) {
  .video-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media (min-width: 680px) {
  .video-list {
    margin-bottom: -30px;
  }
}

@media (min-width: 1280px) {
  .video-list {
    margin-bottom: -40px;
  }
}

.video-list__item {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}

@media (min-width: 680px) {
  .video-list__item {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1280px) {
  .video-list__item {
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 680px) {
  .video-list__item {
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .video-list__item {
    margin-bottom: 40px;
  }
}

.video-list__item > *:only-child {
  height: 100%;
}

.video-cell {
  background-color: #f8f8f8;
  color: #323232;
  position: relative;
  display: block;
  padding: 0.625rem 0.9375rem 3.75rem 0.9375rem;
}

.dark-theme .video-cell {
  background-color: #323232;
  color: #fff;
}

.video-cell > * {
  position: relative;
  z-index: 10;
}

.video-cell__background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 5;
  background: center / cover no-repeat;
}

.video-cell__title {
  min-height: 5.5em;
}

.video-cell__button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 3.125rem;
  height: 3.125rem;
  background-color: #00d7be;
}

.video-cell__button::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1.25rem;
  height: 1.25rem;
  transform: translate(-50%, -50%);
  background: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23323232' fill-rule='nonzero' d='M2 0l16 9.983L2 20V0z'/%3e%3c/svg%3e") center/contain no-repeat;
}

.video-cell:hover {
  color: #00d7be;
}

.video-cell:hover .video-cell__background {
  opacity: 0.25;
}

.video-cell:hover .video-cell__button::after {
  animation: block-arrow-animation 1.8s infinite linear;
}

@media (min-width: 680px) {
  .video-cell {
    padding: 0.9375rem 1.25rem 4.6875rem 1.25rem;
  }
  .video-cell__button {
    width: 3.75rem;
    height: 3.75rem;
  }
}

@media (min-width: 1280px) {
  .video-cell {
    padding: 1.5625rem 1.875rem 5.8125rem 1.875rem;
  }
  .video-cell__button {
    width: 4.25rem;
    height: 4.25rem;
  }
}

.hamburger {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: calc(3.75rem + 1px);
  left: 0;
  width: 100%;
  bottom: 0;
  overflow: auto;
}

.hamburger.closed {
  display: none;
}

.hamburger__menu {
  font-size: 2rem;
  list-style: none;
  flex-grow: 1;
  padding: 1.875rem 20px;
  margin: 0;
}

.hamburger__menu-item:not(:first-child) {
  margin-top: 1.25rem;
}

.hamburger__footer__right a {
  position: relative;
  padding-right: 1.875rem;
}

.hamburger__footer__right a::after {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: center/1.25rem 1.25rem no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23323232' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.dark-theme .hamburger__footer__right a::after {
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23fff' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
}

@media (max-width: 679.98px) {
  .hamburger__footer__left {
    padding: 1.25rem 20px;
  }
  .hamburger__footer__right {
    border-top: 1px solid #e6e6e6;
    padding: 1.25rem 20px;
  }
  .dark-theme .hamburger__footer__right {
    border-top-color: #323232;
  }
}

@media (min-width: 680px) {
  .hamburger {
    top: calc(5rem + 1px);
  }
  .hamburger__menu {
    padding: 3.75rem 30px;
  }
  .hamburger__footer {
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dark-theme .hamburger__footer {
    border-top-color: #323232;
  }
  .hamburger__footer__left {
    padding: 1.25rem 30px;
  }
  .hamburger__footer__right {
    padding: 1.25rem 30px;
  }
}

@media (min-width: 1024px) {
  .hamburger {
    display: none;
  }
}

.trifilter {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.trifilter__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

@media (min-width: 680px) {
  .trifilter__row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 1280px) {
  .trifilter__row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
  }
}

.trifilter__group {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

@media (min-width: 680px) {
  .trifilter__group {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1280px) {
  .trifilter__group {
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 679.98px) {
  .trifilter__group:not(:first-child) {
    margin-top: 0.625rem;
  }
}

@media (min-width: 680px) {
  .trifilter__group {
    flex: 1 0 0px;
  }
}

.trifilter__group.dropdown-select-inited .trifilter__input {
  display: none;
}

.trifilter__dropdown-wrapper {
  z-index: 198;
  position: relative;
}

.trifilter__dropdown-selected {
  position: relative;
  font-size: 18px;
  line-height: 48px !important;
  font-family: "Helvetica Neue", "helvetica", "arial", sans-serif;
  font-weight: 400;
  width: 100%;
  height: 50px !important;
  padding-left: 0.9375rem;
  padding-right: 3.125rem;
  border: 1px solid #e6e6e6;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
}

.trifilter__dropdown-selected:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 3.125rem;
  height: 3.125rem;
  content: '';
  display: block;
  background: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3e%3cg transform='rotate%2890 10 10%29' fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23323232' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e") right 0.9375rem center/1.25rem no-repeat, white;
}

body.dark-theme .trifilter__dropdown-selected {
  color: #fff;
}

body.dark-theme .trifilter__dropdown-selected:after {
  background: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cg transform='rotate%2890 10 10%29' fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23fff' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e") right 0.9375rem center/1.25rem no-repeat, #1e1e1e;
}

.trifilter__dropdown-selected:focus {
  outline: 0;
  border-color: #000;
}

body.dark-theme .trifilter__dropdown-selected:focus {
  border-color: #fff;
}

.trifilter__dropdown-search-wrapper {
  height: 36px;
  padding: 2px 15px;
  margin-bottom: 10px;
}

.trifilter__dropdown-search {
  margin: 0;
  display: block;
  width: 100%;
  line-height: 34px !important;
  border: 1px solid #e6e6e6 !important;
  outline: none !important;
  padding: 0 10px !important;
  height: 36px !important;
  border-radius: 0 !important;
}

.trifilter__dropdown-search:focus {
  border-color: #ced4da !important;
}

body.dark-theme .trifilter__dropdown-search {
  border-color: #fff !important;
  background-color: transparent !important;
  color: #fff !important;
}

.trifilter__dropdown-wrapper ul {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.trifilter__dropdown-toggle {
  display: none;
  position: absolute;
  width: 100%;
  top: calc(100% - 1px);
  left: 0;
  max-height: calc(600% + 1px);
  overflow-y: auto;
  background-color: #fff;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

body.dark-theme .trifilter__dropdown-toggle {
  background-color: #1e1e1e;
}

.trifilter__dropdown-toggle > ul > li {
  font-weight: 400;
}

.trifilter__dropdown-toggle > ul > li.no-match {
  opacity: 0.4;
}

.trifilter__dropdown-toggle > ul > li.no-match span {
  cursor: default !important;
}

.trifilter__dropdown-toggle > ul li.no-match {
  opacity: 0.4;
}

.trifilter__dropdown-toggle > ul li.no-match span {
  cursor: default !important;
}

.trifilter__dropdown-toggle > ul span {
  display: block;
  font-size: 14px;
  line-height: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.trifilter__dropdown-toggle > ul span.optgroup-title {
  font-weight: bold;
}

.trifilter__dropdown-toggle > ul span.option-title {
  cursor: pointer;
}

.trifilter__dropdown-toggle > ul span.option-title.hidden {
  display: none;
}

.trifilter__dropdown-toggle > ul span.option-title:hover {
  background-color: #f8f8f8;
}

body.dark-theme .trifilter__dropdown-toggle > ul span.option-title:hover {
  background-color: #6c757d;
}

.trifilter__dropdown-toggle .optgroup-wrapper.hidden {
  display: none;
}

.trifilter__dropdown-toggle > ul > li > ul span.option-title {
  padding-left: 30px;
}

.trifilter__dropdown-select-opened {
  z-index: 199;
}

.trifilter__dropdown-select-opened .trifilter__dropdown-toggle {
  display: block;
}

.trifilter__dropdown-select-opened .trifilter__dropdown-wrapper {
  border-bottom-color: transparent;
}

.trifilter__input {
  width: 100%;
  height: 3.125rem;
  padding-left: 0.9375rem;
  padding-right: 3.125rem;
  border: 1px solid #e6e6e6;
  background: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3e%3cg transform='rotate%2890 10 10%29' fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23323232' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e") right 0.9375rem center/1.25rem no-repeat;
}

body.dark-theme .trifilter__input {
  color: #fff;
  background: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cg transform='rotate%2890 10 10%29' fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%23fff' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e") right 0.9375rem center/1.25rem no-repeat;
}

.trifilter__input:focus {
  outline: 0;
  border-color: #000;
}

body.dark-theme .trifilter__input:focus {
  border-color: #fff;
}

.filterable-list {
  margin-top: 2.5rem;
  margin-bottom: 3.75rem;
  padding-top: 0.1px;
  padding-bottom: 0.1px;
}

.filterable-list__no-result {
  margin-top: 1.25rem;
  margin-bottom: 3.75rem;
  text-align: center;
}

.filterable-list__no-result__title {
  line-height: 1.31;
}

.filterable-list__no-result__title::before {
  content: '';
  display: block;
  margin: 0 auto 0.625rem;
  width: 2.5rem;
  height: 2.5rem;
  background: url("data:image/svg+xml,%3csvg width='40' height='40' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='%2300d7be' fill-rule='nonzero'%3e%3cpath d='M17.615 0C7.902 0 0 7.902 0 17.615 0 27.328 7.902 35.23 17.615 35.23c9.713 0 17.615-7.902 17.615-17.615C35.23 7.902 27.328 0 17.615 0zm0 31.978c-7.92 0-14.363-6.443-14.363-14.363S9.695 3.252 17.615 3.252s14.363 6.443 14.363 14.363-6.443 14.363-14.363 14.363z'/%3e%3cpath d='M39.524 37.224L30.2 27.902a1.625 1.625 0 10-2.3 2.3l9.323 9.322a1.621 1.621 0 002.3 0 1.625 1.625 0 000-2.3z'/%3e%3c/g%3e%3c/svg%3e") center/contain no-repeat;
}

.filterable-list__no-result__text {
  margin-top: 1.875rem;
}

.filterable-list__list {
  font-size: 1.125rem;
  list-style: none;
  padding: 0;
}

.filterable-list__item.odd {
  background-color: #f8f8f8;
}

body.dark-theme .filterable-list__item.odd {
  background-color: rgba(248, 248, 248, 0.2);
}

.filterable-list__item.even {
  background-color: #fff;
}

body.dark-theme .filterable-list__item.even {
  background-color: rgba(255, 255, 255, 0.1);
}

.filterable-list__item.mismatch {
  display: none;
}

.filterable-list__item__date {
  color: #828282;
}

body.dark-theme .filterable-list__item__date {
  color: #fff;
}

.filterable-list__item__content p {
  margin: 0 0 0.25em 0;
}

.filterable-list__item__content a {
  position: relative;
  padding-left: 1.875rem;
  display: inline-block;
  color: #00d7be;
}

.filterable-list__item__content a::before {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: center/1.25rem 1.25rem no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%2300d7be' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.filterable-list__control {
  background-color: #00d7be;
}

.filterable-list__control__show-more {
  width: 100%;
  height: 3.125rem;
  text-align: center;
}

@media (max-width: 1279.98px) {
  .filterable-list__item {
    min-height: 11em;
    padding: 0.9375rem 20px;
  }
  .filterable-list__item__date {
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 679.98px) {
  .filterable-list {
    padding-left: 0;
    padding-right: 0;
  }
  .filterable-list__no-result {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1280px) {
  .filterable-list__no-result {
    margin-top: 3.75rem;
    margin-bottom: 8.75rem;
  }
  .filterable-list__list {
    font-size: 1.25rem;
  }
  .filterable-list__item {
    min-height: 6.5em;
    display: flex;
  }
  .filterable-list__item__date {
    width: calc( (100% - 40px * 2) / 3);
    padding: 0.9375rem 0 0.9375rem 20px;
  }
  .filterable-list__item__content {
    flex: 1 0 0px;
    padding: 0.9375rem 50px 0.9375rem 40px;
  }
}

.download-cell {
  font-size: 0.9375rem;
}

@media (max-width: 1440px) {
  .download-cell {
    font-size: calc(0.8375rem + 0.11111vw) ;
  }
}

.download-cell > *:first-child {
  margin-top: 0 !important;
}

.download-cell__image img {
  width: 100%;
  height: auto;
}

.download-cell__title {
  line-height: 1.3;
  margin-top: 0.5rem;
}

.download-cell__links {
  margin-top: 0.375rem;
  color: #00d7be;
}

.download-cell__link {
  position: relative;
  padding-left: 1.875rem;
  line-height: 1.3;
  display: block;
}

.download-cell__link::before {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: center/1.25rem 1.25rem no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%2300d7be' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.download-cell__text {
  margin-top: 0.625rem;
}

@media (min-width: 1024px) {
  .download-cell__image {
    margin-bottom: 1.25rem;
  }
  .download-cell__title {
    margin-top: 1.25rem;
  }
  .download-cell__links {
    margin-top: 0.9375rem;
  }
  .download-cell__text {
    margin-top: 1.25rem;
  }
}

.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 30, 30, 0.95);
  z-index: 1050;
  display: none;
}

.video-popup.show {
  display: block;
}

.video-popup__close-button {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  margin: 1.25rem;
  width: 1.5rem;
  height: 1.5rem;
  background: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M19.428 19.428c.31-.318.31-.869 0-1.178L11.178 10l8.25-8.25c.31-.318.318-.869 0-1.178a.864.864 0 00-1.178 0L10 8.822 1.75.571C1.441.262.881.254.572.572c-.31.318-.31.878 0 1.178L8.822 10l-8.25 8.25c-.31.309-.318.869 0 1.178.318.31.869.31 1.178 0l8.25-8.25 8.25 8.25c.318.31.869.318 1.178 0z' fill='%23fff' fill-rule='nonzero'/%3e%3c/svg%3e") center/contain no-repeat;
  z-index: 10;
}

.video-popup__iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  width: 100vmin;
  height: 100vmin;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0;
}

@media (min-width: 680px) {
  .video-popup__close-button {
    margin: 1.875rem;
  }
  .video-popup__iframe {
    top: 6.25rem;
    left: 6.25rem;
    width: calc(100vw - 12.5rem);
    height: calc(100vh - 12.5rem);
    transform: none;
  }
}

.article .hnav, .article .breadcrumb, .article .page-header, .article .page-footer .container, .page-footer .article .container, .article .featured-article, .article .articles-list, .article .article-header, .article .article-text-section, .article .image-module, .article .image-slider__container, .article .related-links, .article .seo-content, .article .hr, .article .masonry, .article .video-grid, .article .trifilter, .article .filterable-list, .article .multi-column, .article .mm-set-header {
  max-width: 974px;
}

.multi-column > .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

@media (min-width: 680px) {
  .multi-column > .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 1280px) {
  .multi-column > .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
  }
}

.multi-column > .row > .col-1, .multi-column > .row > .col-2, .multi-column > .row > .col-3, .multi-column > .row > .col-4, .multi-column > .row > .col-5, .multi-column > .row > .col-6, .multi-column > .row > .col-7, .multi-column > .row > .col-8, .multi-column > .row > .col-9, .multi-column > .row > .col-10, .multi-column > .row > .col-11, .multi-column > .row > .col-12, .multi-column > .row > .col,
.multi-column > .row > .col-auto, .multi-column > .row > .col-sm-1, .multi-column > .row > .col-sm-2, .multi-column > .row > .col-sm-3, .multi-column > .row > .col-sm-4, .multi-column > .row > .col-sm-5, .multi-column > .row > .col-sm-6, .multi-column > .row > .col-sm-7, .multi-column > .row > .col-sm-8, .multi-column > .row > .col-sm-9, .multi-column > .row > .col-sm-10, .multi-column > .row > .col-sm-11, .multi-column > .row > .col-sm-12, .multi-column > .row > .col-sm,
.multi-column > .row > .col-sm-auto, .multi-column > .row > .col-md-1, .multi-column > .row > .col-md-2, .multi-column > .row > .col-md-3, .multi-column > .row > .col-md-4, .multi-column > .row > .col-md-5, .multi-column > .row > .col-md-6, .multi-column > .row > .col-md-7, .multi-column > .row > .col-md-8, .multi-column > .row > .col-md-9, .multi-column > .row > .col-md-10, .multi-column > .row > .col-md-11, .multi-column > .row > .col-md-12, .multi-column > .row > .col-md,
.multi-column > .row > .col-md-auto, .multi-column > .row > .col-lg-1, .multi-column > .row > .col-lg-2, .multi-column > .row > .col-lg-3, .multi-column > .row > .col-lg-4, .multi-column > .row > .col-lg-5, .multi-column > .row > .col-lg-6, .multi-column > .row > .col-lg-7, .multi-column > .row > .col-lg-8, .multi-column > .row > .col-lg-9, .multi-column > .row > .col-lg-10, .multi-column > .row > .col-lg-11, .multi-column > .row > .col-lg-12, .multi-column > .row > .col-lg,
.multi-column > .row > .col-lg-auto, .multi-column > .row > .col-xl-1, .multi-column > .row > .col-xl-2, .multi-column > .row > .col-xl-3, .multi-column > .row > .col-xl-4, .multi-column > .row > .col-xl-5, .multi-column > .row > .col-xl-6, .multi-column > .row > .col-xl-7, .multi-column > .row > .col-xl-8, .multi-column > .row > .col-xl-9, .multi-column > .row > .col-xl-10, .multi-column > .row > .col-xl-11, .multi-column > .row > .col-xl-12, .multi-column > .row > .col-xl,
.multi-column > .row > .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

@media (min-width: 680px) {
  .multi-column > .row > .col-1, .multi-column > .row > .col-2, .multi-column > .row > .col-3, .multi-column > .row > .col-4, .multi-column > .row > .col-5, .multi-column > .row > .col-6, .multi-column > .row > .col-7, .multi-column > .row > .col-8, .multi-column > .row > .col-9, .multi-column > .row > .col-10, .multi-column > .row > .col-11, .multi-column > .row > .col-12, .multi-column > .row > .col,
  .multi-column > .row > .col-auto, .multi-column > .row > .col-sm-1, .multi-column > .row > .col-sm-2, .multi-column > .row > .col-sm-3, .multi-column > .row > .col-sm-4, .multi-column > .row > .col-sm-5, .multi-column > .row > .col-sm-6, .multi-column > .row > .col-sm-7, .multi-column > .row > .col-sm-8, .multi-column > .row > .col-sm-9, .multi-column > .row > .col-sm-10, .multi-column > .row > .col-sm-11, .multi-column > .row > .col-sm-12, .multi-column > .row > .col-sm,
  .multi-column > .row > .col-sm-auto, .multi-column > .row > .col-md-1, .multi-column > .row > .col-md-2, .multi-column > .row > .col-md-3, .multi-column > .row > .col-md-4, .multi-column > .row > .col-md-5, .multi-column > .row > .col-md-6, .multi-column > .row > .col-md-7, .multi-column > .row > .col-md-8, .multi-column > .row > .col-md-9, .multi-column > .row > .col-md-10, .multi-column > .row > .col-md-11, .multi-column > .row > .col-md-12, .multi-column > .row > .col-md,
  .multi-column > .row > .col-md-auto, .multi-column > .row > .col-lg-1, .multi-column > .row > .col-lg-2, .multi-column > .row > .col-lg-3, .multi-column > .row > .col-lg-4, .multi-column > .row > .col-lg-5, .multi-column > .row > .col-lg-6, .multi-column > .row > .col-lg-7, .multi-column > .row > .col-lg-8, .multi-column > .row > .col-lg-9, .multi-column > .row > .col-lg-10, .multi-column > .row > .col-lg-11, .multi-column > .row > .col-lg-12, .multi-column > .row > .col-lg,
  .multi-column > .row > .col-lg-auto, .multi-column > .row > .col-xl-1, .multi-column > .row > .col-xl-2, .multi-column > .row > .col-xl-3, .multi-column > .row > .col-xl-4, .multi-column > .row > .col-xl-5, .multi-column > .row > .col-xl-6, .multi-column > .row > .col-xl-7, .multi-column > .row > .col-xl-8, .multi-column > .row > .col-xl-9, .multi-column > .row > .col-xl-10, .multi-column > .row > .col-xl-11, .multi-column > .row > .col-xl-12, .multi-column > .row > .col-xl,
  .multi-column > .row > .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1280px) {
  .multi-column > .row > .col-1, .multi-column > .row > .col-2, .multi-column > .row > .col-3, .multi-column > .row > .col-4, .multi-column > .row > .col-5, .multi-column > .row > .col-6, .multi-column > .row > .col-7, .multi-column > .row > .col-8, .multi-column > .row > .col-9, .multi-column > .row > .col-10, .multi-column > .row > .col-11, .multi-column > .row > .col-12, .multi-column > .row > .col,
  .multi-column > .row > .col-auto, .multi-column > .row > .col-sm-1, .multi-column > .row > .col-sm-2, .multi-column > .row > .col-sm-3, .multi-column > .row > .col-sm-4, .multi-column > .row > .col-sm-5, .multi-column > .row > .col-sm-6, .multi-column > .row > .col-sm-7, .multi-column > .row > .col-sm-8, .multi-column > .row > .col-sm-9, .multi-column > .row > .col-sm-10, .multi-column > .row > .col-sm-11, .multi-column > .row > .col-sm-12, .multi-column > .row > .col-sm,
  .multi-column > .row > .col-sm-auto, .multi-column > .row > .col-md-1, .multi-column > .row > .col-md-2, .multi-column > .row > .col-md-3, .multi-column > .row > .col-md-4, .multi-column > .row > .col-md-5, .multi-column > .row > .col-md-6, .multi-column > .row > .col-md-7, .multi-column > .row > .col-md-8, .multi-column > .row > .col-md-9, .multi-column > .row > .col-md-10, .multi-column > .row > .col-md-11, .multi-column > .row > .col-md-12, .multi-column > .row > .col-md,
  .multi-column > .row > .col-md-auto, .multi-column > .row > .col-lg-1, .multi-column > .row > .col-lg-2, .multi-column > .row > .col-lg-3, .multi-column > .row > .col-lg-4, .multi-column > .row > .col-lg-5, .multi-column > .row > .col-lg-6, .multi-column > .row > .col-lg-7, .multi-column > .row > .col-lg-8, .multi-column > .row > .col-lg-9, .multi-column > .row > .col-lg-10, .multi-column > .row > .col-lg-11, .multi-column > .row > .col-lg-12, .multi-column > .row > .col-lg,
  .multi-column > .row > .col-lg-auto, .multi-column > .row > .col-xl-1, .multi-column > .row > .col-xl-2, .multi-column > .row > .col-xl-3, .multi-column > .row > .col-xl-4, .multi-column > .row > .col-xl-5, .multi-column > .row > .col-xl-6, .multi-column > .row > .col-xl-7, .multi-column > .row > .col-xl-8, .multi-column > .row > .col-xl-9, .multi-column > .row > .col-xl-10, .multi-column > .row > .col-xl-11, .multi-column > .row > .col-xl-12, .multi-column > .row > .col-xl,
  .multi-column > .row > .col-xl-auto {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.multi-column > .row > .col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.multi-column > .row > .row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.multi-column > .row > .row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.multi-column > .row > .row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.multi-column > .row > .row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.multi-column > .row > .row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.multi-column > .row > .row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.multi-column > .row > .col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.multi-column > .row > .col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.multi-column > .row > .col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.multi-column > .row > .col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.multi-column > .row > .col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.multi-column > .row > .col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.multi-column > .row > .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.multi-column > .row > .col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.multi-column > .row > .col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.multi-column > .row > .col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.multi-column > .row > .col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.multi-column > .row > .col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.multi-column > .row > .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.multi-column > .row > .order-first {
  order: -1;
}

.multi-column > .row > .order-last {
  order: 13;
}

.multi-column > .row > .order-0 {
  order: 0;
}

.multi-column > .row > .order-1 {
  order: 1;
}

.multi-column > .row > .order-2 {
  order: 2;
}

.multi-column > .row > .order-3 {
  order: 3;
}

.multi-column > .row > .order-4 {
  order: 4;
}

.multi-column > .row > .order-5 {
  order: 5;
}

.multi-column > .row > .order-6 {
  order: 6;
}

.multi-column > .row > .order-7 {
  order: 7;
}

.multi-column > .row > .order-8 {
  order: 8;
}

.multi-column > .row > .order-9 {
  order: 9;
}

.multi-column > .row > .order-10 {
  order: 10;
}

.multi-column > .row > .order-11 {
  order: 11;
}

.multi-column > .row > .order-12 {
  order: 12;
}

.multi-column > .row > .offset-1 {
  margin-left: 8.33333%;
}

.multi-column > .row > .offset-2 {
  margin-left: 16.66667%;
}

.multi-column > .row > .offset-3 {
  margin-left: 25%;
}

.multi-column > .row > .offset-4 {
  margin-left: 33.33333%;
}

.multi-column > .row > .offset-5 {
  margin-left: 41.66667%;
}

.multi-column > .row > .offset-6 {
  margin-left: 50%;
}

.multi-column > .row > .offset-7 {
  margin-left: 58.33333%;
}

.multi-column > .row > .offset-8 {
  margin-left: 66.66667%;
}

.multi-column > .row > .offset-9 {
  margin-left: 75%;
}

.multi-column > .row > .offset-10 {
  margin-left: 83.33333%;
}

.multi-column > .row > .offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 375px) {
  .multi-column > .row > .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .multi-column > .row > .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .multi-column > .row > .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .multi-column > .row > .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .multi-column > .row > .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .multi-column > .row > .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .multi-column > .row > .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .multi-column > .row > .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .multi-column > .row > .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .multi-column > .row > .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .multi-column > .row > .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .multi-column > .row > .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .multi-column > .row > .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .multi-column > .row > .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .multi-column > .row > .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .multi-column > .row > .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .multi-column > .row > .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .multi-column > .row > .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .multi-column > .row > .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .multi-column > .row > .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .multi-column > .row > .order-sm-first {
    order: -1;
  }
  .multi-column > .row > .order-sm-last {
    order: 13;
  }
  .multi-column > .row > .order-sm-0 {
    order: 0;
  }
  .multi-column > .row > .order-sm-1 {
    order: 1;
  }
  .multi-column > .row > .order-sm-2 {
    order: 2;
  }
  .multi-column > .row > .order-sm-3 {
    order: 3;
  }
  .multi-column > .row > .order-sm-4 {
    order: 4;
  }
  .multi-column > .row > .order-sm-5 {
    order: 5;
  }
  .multi-column > .row > .order-sm-6 {
    order: 6;
  }
  .multi-column > .row > .order-sm-7 {
    order: 7;
  }
  .multi-column > .row > .order-sm-8 {
    order: 8;
  }
  .multi-column > .row > .order-sm-9 {
    order: 9;
  }
  .multi-column > .row > .order-sm-10 {
    order: 10;
  }
  .multi-column > .row > .order-sm-11 {
    order: 11;
  }
  .multi-column > .row > .order-sm-12 {
    order: 12;
  }
  .multi-column > .row > .offset-sm-0 {
    margin-left: 0;
  }
  .multi-column > .row > .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .multi-column > .row > .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .multi-column > .row > .offset-sm-3 {
    margin-left: 25%;
  }
  .multi-column > .row > .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .multi-column > .row > .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .multi-column > .row > .offset-sm-6 {
    margin-left: 50%;
  }
  .multi-column > .row > .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .multi-column > .row > .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .multi-column > .row > .offset-sm-9 {
    margin-left: 75%;
  }
  .multi-column > .row > .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .multi-column > .row > .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 680px) {
  .multi-column > .row > .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .multi-column > .row > .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .multi-column > .row > .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .multi-column > .row > .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .multi-column > .row > .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .multi-column > .row > .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .multi-column > .row > .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .multi-column > .row > .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .multi-column > .row > .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .multi-column > .row > .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .multi-column > .row > .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .multi-column > .row > .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .multi-column > .row > .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .multi-column > .row > .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .multi-column > .row > .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .multi-column > .row > .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .multi-column > .row > .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .multi-column > .row > .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .multi-column > .row > .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .multi-column > .row > .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .multi-column > .row > .order-md-first {
    order: -1;
  }
  .multi-column > .row > .order-md-last {
    order: 13;
  }
  .multi-column > .row > .order-md-0 {
    order: 0;
  }
  .multi-column > .row > .order-md-1 {
    order: 1;
  }
  .multi-column > .row > .order-md-2 {
    order: 2;
  }
  .multi-column > .row > .order-md-3 {
    order: 3;
  }
  .multi-column > .row > .order-md-4 {
    order: 4;
  }
  .multi-column > .row > .order-md-5 {
    order: 5;
  }
  .multi-column > .row > .order-md-6 {
    order: 6;
  }
  .multi-column > .row > .order-md-7 {
    order: 7;
  }
  .multi-column > .row > .order-md-8 {
    order: 8;
  }
  .multi-column > .row > .order-md-9 {
    order: 9;
  }
  .multi-column > .row > .order-md-10 {
    order: 10;
  }
  .multi-column > .row > .order-md-11 {
    order: 11;
  }
  .multi-column > .row > .order-md-12 {
    order: 12;
  }
  .multi-column > .row > .offset-md-0 {
    margin-left: 0;
  }
  .multi-column > .row > .offset-md-1 {
    margin-left: 8.33333%;
  }
  .multi-column > .row > .offset-md-2 {
    margin-left: 16.66667%;
  }
  .multi-column > .row > .offset-md-3 {
    margin-left: 25%;
  }
  .multi-column > .row > .offset-md-4 {
    margin-left: 33.33333%;
  }
  .multi-column > .row > .offset-md-5 {
    margin-left: 41.66667%;
  }
  .multi-column > .row > .offset-md-6 {
    margin-left: 50%;
  }
  .multi-column > .row > .offset-md-7 {
    margin-left: 58.33333%;
  }
  .multi-column > .row > .offset-md-8 {
    margin-left: 66.66667%;
  }
  .multi-column > .row > .offset-md-9 {
    margin-left: 75%;
  }
  .multi-column > .row > .offset-md-10 {
    margin-left: 83.33333%;
  }
  .multi-column > .row > .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1024px) {
  .multi-column > .row > .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .multi-column > .row > .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .multi-column > .row > .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .multi-column > .row > .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .multi-column > .row > .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .multi-column > .row > .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .multi-column > .row > .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .multi-column > .row > .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .multi-column > .row > .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .multi-column > .row > .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .multi-column > .row > .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .multi-column > .row > .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .multi-column > .row > .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .multi-column > .row > .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .multi-column > .row > .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .multi-column > .row > .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .multi-column > .row > .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .multi-column > .row > .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .multi-column > .row > .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .multi-column > .row > .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .multi-column > .row > .order-lg-first {
    order: -1;
  }
  .multi-column > .row > .order-lg-last {
    order: 13;
  }
  .multi-column > .row > .order-lg-0 {
    order: 0;
  }
  .multi-column > .row > .order-lg-1 {
    order: 1;
  }
  .multi-column > .row > .order-lg-2 {
    order: 2;
  }
  .multi-column > .row > .order-lg-3 {
    order: 3;
  }
  .multi-column > .row > .order-lg-4 {
    order: 4;
  }
  .multi-column > .row > .order-lg-5 {
    order: 5;
  }
  .multi-column > .row > .order-lg-6 {
    order: 6;
  }
  .multi-column > .row > .order-lg-7 {
    order: 7;
  }
  .multi-column > .row > .order-lg-8 {
    order: 8;
  }
  .multi-column > .row > .order-lg-9 {
    order: 9;
  }
  .multi-column > .row > .order-lg-10 {
    order: 10;
  }
  .multi-column > .row > .order-lg-11 {
    order: 11;
  }
  .multi-column > .row > .order-lg-12 {
    order: 12;
  }
  .multi-column > .row > .offset-lg-0 {
    margin-left: 0;
  }
  .multi-column > .row > .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .multi-column > .row > .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .multi-column > .row > .offset-lg-3 {
    margin-left: 25%;
  }
  .multi-column > .row > .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .multi-column > .row > .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .multi-column > .row > .offset-lg-6 {
    margin-left: 50%;
  }
  .multi-column > .row > .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .multi-column > .row > .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .multi-column > .row > .offset-lg-9 {
    margin-left: 75%;
  }
  .multi-column > .row > .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .multi-column > .row > .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1280px) {
  .multi-column > .row > .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .multi-column > .row > .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .multi-column > .row > .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .multi-column > .row > .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .multi-column > .row > .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .multi-column > .row > .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .multi-column > .row > .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .multi-column > .row > .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .multi-column > .row > .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .multi-column > .row > .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .multi-column > .row > .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .multi-column > .row > .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .multi-column > .row > .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .multi-column > .row > .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .multi-column > .row > .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .multi-column > .row > .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .multi-column > .row > .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .multi-column > .row > .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .multi-column > .row > .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .multi-column > .row > .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .multi-column > .row > .order-xl-first {
    order: -1;
  }
  .multi-column > .row > .order-xl-last {
    order: 13;
  }
  .multi-column > .row > .order-xl-0 {
    order: 0;
  }
  .multi-column > .row > .order-xl-1 {
    order: 1;
  }
  .multi-column > .row > .order-xl-2 {
    order: 2;
  }
  .multi-column > .row > .order-xl-3 {
    order: 3;
  }
  .multi-column > .row > .order-xl-4 {
    order: 4;
  }
  .multi-column > .row > .order-xl-5 {
    order: 5;
  }
  .multi-column > .row > .order-xl-6 {
    order: 6;
  }
  .multi-column > .row > .order-xl-7 {
    order: 7;
  }
  .multi-column > .row > .order-xl-8 {
    order: 8;
  }
  .multi-column > .row > .order-xl-9 {
    order: 9;
  }
  .multi-column > .row > .order-xl-10 {
    order: 10;
  }
  .multi-column > .row > .order-xl-11 {
    order: 11;
  }
  .multi-column > .row > .order-xl-12 {
    order: 12;
  }
  .multi-column > .row > .offset-xl-0 {
    margin-left: 0;
  }
  .multi-column > .row > .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .multi-column > .row > .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .multi-column > .row > .offset-xl-3 {
    margin-left: 25%;
  }
  .multi-column > .row > .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .multi-column > .row > .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .multi-column > .row > .offset-xl-6 {
    margin-left: 50%;
  }
  .multi-column > .row > .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .multi-column > .row > .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .multi-column > .row > .offset-xl-9 {
    margin-left: 75%;
  }
  .multi-column > .row > .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .multi-column > .row > .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.multi-column > .row > .col--child-px-0 > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sb-quick-links {
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f8f8f8;
}

@media (min-width: 680px) {
  .sb-quick-links {
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .sb-quick-links {
    padding: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.sb-quick-links__list-wrapper {
  position: relative;
  margin-top: 1.5625rem;
}

.sb-quick-links__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sb-quick-links__list-item {
  border-bottom: 1px solid rgba(127, 127, 127, 0.13);
}

.sb-quick-links__list-item__link {
  display: flex;
  position: relative;
  margin: 0.9375rem 0;
  min-height: 4.5em;
}

.sb-quick-links__list-item__date {
  width: 4.6875rem;
  flex: 0 0 auto;
}

.sb-quick-links__list-item__date .day,
.sb-quick-links__list-item__date .month {
  position: absolute;
  left: 0;
  color: #00d7be;
}

.sb-quick-links__list-item__date .day {
  font-size: 2.5rem;
  line-height: 1;
  top: -0.125rem;
  font-weight: normal;
}

.sb-quick-links__list-item__date .month {
  font-size: 0.75rem;
  line-height: 1.5;
  top: 2.75rem;
  text-transform: uppercase;
  letter-spacing: 0.083em;
}

.sb-quick-links__list-item__title {
  flex-grow: 1;
}

.sb-quick-links__list-item__image {
  flex: 0 0 auto;
}

.sb-quick-links__list-item__image img {
  margin-left: 0.9375rem;
  width: 4.6875rem;
}

.sb-quick-links__links {
  margin-top: 1.25rem;
}

.sb-quick-links__links a {
  color: #00d7be;
}

.sb-quick-links__links a::after {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: center/1.25rem 1.25rem no-repeat;
  background-image: url("data:image/svg+xml,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h20v20H0z'/%3e%3cpath d='M11.81 18c-.577 0-1.007-.41-1.007-.974 0-.277.102-.532.283-.709l2.398-2.392 3.45-3.067-2.567.155H1.029c-.61 0-1.029-.42-1.029-1.007 0-.598.419-1.02 1.03-1.02h13.337l2.556.156-3.439-3.067-2.398-2.392a.984.984 0 01-.283-.709c0-.564.43-.974 1.007-.974.271 0 .52.1.77.332l7.07 6.91c.226.21.35.476.35.764 0 .276-.124.542-.35.752l-7.093 6.932c-.227.21-.476.31-.747.31z' fill='%2300d7be' fill-rule='nonzero'/%3e%3c/g%3e%3c/svg%3e");
  content: '';
  display: inline-block;
  margin-left: 0.625rem;
  vertical-align: middle;
}

.mm-set-header {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 680px) {
  .mm-set-header {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .mm-set-header {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.mm-set-header__title {
  font-size: 2rem;
  line-height: 1.19;
}

@media (max-width: 1440px) {
  .mm-set-header__title {
    font-size: calc(1.05rem + 1.05556vw) ;
  }
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  src: url(../fonts/HelveticaNeueLTStd-Roman.woff);
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 500;
  src: url(../fonts/HelveticaNeueLTStd-Md.woff);
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  src: url(../fonts/HelveticaNeueLTStd-Bd.woff);
}
