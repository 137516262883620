@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@keyframes masonryArrow {
  0% { transform: translate(-50%, -50%); }
  7% { transform: translate(150%, -50%); }
  7.001% { transform: translate(-150%, -50%); opacity: 0; }
  14%, 100% { transform: translate(-50%, -50%);opacity: 1; }
}

.masonry {
  @extend %container;

  &__cell {
    @include card-themed();
    transition: box-shadow 0.4s;

    &:hover {
      box-shadow: 0 30px 50px 0 rgba(0,0,0,.75)
    }
  }

  // 1 col
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 3;

    &__cell {
      &:not(:first-child) {
        margin-top: $grid-gutter-width;
      }

      &--a1 { order: 1; }
      &--b1 { order: 2; }
      &--c1 { order: 3; }
      &--b2 { order: 4; }
      &--c2 { order: 5; }
      &--a2 { order: 6; }
      &--c3 { order: 7; }
      &--b3 { order: 8; }
      &--c4 { order: 9; }
    }
  }

  // 2 col
  @include media-breakpoint-only(md) {
    $g: $grid-gutter-width-md;
    display: -ms-grid;
    -ms-grid-columns: 1fr $g 1fr;
    -ms-grid-rows: 1fr $g 0.2fr 0.8fr $g 1.3fr $g 0.5fr $g 0.5fr;
    display: grid;
    grid-template-columns: 1fr $g 1fr;
    grid-template-rows: 1fr $g 0.2fr 0.8fr $g 1.3fr $g 0.5fr $g 0.5fr;
    margin-top: $spacer * 3;
    margin-bottom: $spacer * 3;

    &__cell {
      // in experience editor, when there is only 1 child in the dynamic placeholder
      //   the rendering will be surrounded by 2 code.scpm in front and 2 code.scpm behind
      // and when there is no child rendering, the placeholder will be in the third place out of all 4 siblings
      > *:only-child,
      > [sc-part-of="placeholder rendering"]:nth-child(3):nth-last-child(3),
      > [sc-part-of="placeholder"]:nth-child(3):nth-last-child(2) {
        height: 100%;
      }

      &--c1 {
        margin-top: $g;
      }

      &--a1 { @include grid-pos(1, 3, 1); }
      &--a2 { @include grid-pos(6, 1, 3); }
      &--b1 { @include grid-pos(1, 1, 3); }
      &--b2 { @include grid-pos(3, 2, 3); }
      &--b3 { @include grid-pos(8, 1, 1); }
      &--c1 { @include grid-pos(4, 3, 1); }
      &--c2 { @include grid-pos(10, 1, 1); }
      &--c3 { @include grid-pos(10, 1, 3); }
      &--c4 { @include grid-pos(8, 1, 3); }
    }
  }

  // 3 col (but spacing is still based on md)
  @include media-breakpoint-up(lg) {
    $g: $grid-gutter-width-md;
    display: -ms-grid;
    -ms-grid-columns: 1fr $g 1fr $g 1fr;
    -ms-grid-rows: 1fr $g 1fr $g 0.5fr 0.5fr $g 1fr $g 1fr;
    display: grid;
    grid-template-columns: 1fr $g 1fr $g 1fr;
    grid-template-rows: 1fr $g 1fr $g 0.5fr 0.5fr $g 1fr $g 1fr;
    margin-top: $spacer * 3;
    margin-bottom: $spacer * 3;

    &__cell {
      > *:only-child,
      > [sc-part-of="placeholder rendering"]:nth-child(3):nth-last-child(3),
      > [sc-part-of="placeholder"]:nth-child(3):nth-last-child(2) { // see above
        height: 100%;
      }

      &--a2 {
        margin-top: $g;
      }

      &--a1 { @include grid-pos(1, 5, 1); }
      &--a2 { @include grid-pos(6, 5, 1); }
      &--b1 { @include grid-pos(1, 3, 3); }
      &--b2 { @include grid-pos(5, 4, 3); }
      &--b3 { @include grid-pos(10, 1, 3); }
      &--c1 { @include grid-pos(1, 3, 5); }
      &--c2 { @include grid-pos(5, 2, 5); }
      &--c3 { @include grid-pos(8, 1, 5); }
      &--c4 { @include grid-pos(10, 1, 5); }
    }
  }

  // adjust spacing for xl
  @include media-breakpoint-up(xl) {
    $g: $grid-gutter-width-xl;
    -ms-grid-columns: 1fr $g 1fr $g 1fr;
    -ms-grid-rows: 1fr $g 1fr $g 0.5fr 0.5fr $g 1fr $g 1fr;
    grid-template-columns: 1fr $g 1fr $g 1fr;
    grid-template-rows: 1fr $g 1fr $g 0.5fr 0.5fr $g 1fr $g 1fr;
    margin-top: $spacer * 4;
    margin-bottom: $spacer * 6;

    &__cell {
      &--a2 {
        margin-top: $g;
      }
    }
  }
}

%masonry-cell-padding {
  padding: ($spacer * 0.75) $spacer
    $spacer $spacer;

  @include media-breakpoint-up(xl) {
    padding: $spacer ($spacer * 1.25);
  }
}

.masonry-latest {
  $this: &;
  position: relative;
  display: flex;
  flex-direction: column;

  &__content {
    @extend %masonry-cell-padding;
  }

  &__title {
    @extend %h2-adaptive;
    line-height: 1.11;
  }

  &__date {
    color: $text-muted;
    margin-top: $spacer * 1.5;
    margin-bottom: $spacer * 0.25;
  }

  &__text-title {
    @include font-size($font-size-lg);
  }

  &__image {
    flex-grow: 1;
    min-height: 200px;
    background: center / cover no-repeat;
  }

  &__button {
    position: absolute;
    right: 0;
    bottom: 0;
    width: $square-button-side;
    height: $square-button-side;
    background: $primary;

    &::after {
      @include the-button-arrow();
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover{
    #{$this}__text-title {
      // opacity: 50%;
      color: $primary;
    }
    #{$this}__button {
      &::after {
        animation: masonryArrow 2.4s infinite linear;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__text-title {
      padding-bottom: $spacer;
    }

    &__date {
      margin-top: $spacer * 2.5;
      margin-bottom: $spacer * 0.5;
    }

    &__button {
      width: $square-button-side-xl;
      height: $square-button-side-xl;
    }
  }
}

.masonry-sqlink {
  $this: &;
  @include height-support();
  position: relative;

  &__content {
    @extend %masonry-cell-padding;
    @extend %h4-adaptive-revised;
    position: relative;
    line-height: 1.31;
    width: 100%;
    overflow: hidden;
  }

  &__content_title{
    position: relative;
    z-index: 2;
  }

  &__content_image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    width: 100%;
    transition: transform 0.2s;
    background: center / cover no-repeat;
  }

  &__supplement {
    @extend %masonry-cell-padding;
    @include font-size($font-size-sm);
    line-height: 1.39;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 64%; // empirical
  }

  &--half {
    @include height-support(45%);
  }
  &:hover{
    #{$this}__content_image {
      transform: scale(1.1);
      &:after{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.25);
        content: '';
        display: block;
      }
    }
  }
}

.masonry-articles {
  @extend %masonry-cell-padding;


  &__title {
    @extend %h4-adaptive;
    line-height: 1.31;
  }

  &__list-wrapper {
    position: relative;
    margin-top: $spacer * 1.25;
    @extend %styled-scrollbar-invert;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0 5px 0 0;
  }

  &__list-item {
    $this: &;
    border-bottom: $border-width solid rgba(127, 127, 127, 0.13);

    &__link {
      display: flex;
      position: relative;
      margin: ($spacer * 0.75) 0;
      min-height: 4.5em;
    }

    &__date {
      width: 4.6875rem; // 75
      flex: 0 0 auto;

      // .day and .month is intentionally unresponsive according to design
      .day,
      .month {
        position: absolute;
        left: 0;
        color: $primary;
      }

      .day {
        font-size: 2.5rem; // 40
        line-height: 1;
        top: -0.125rem; // -2
        font-weight: normal;
      }

      .month {
        font-size: 0.75rem; // 12
        line-height: 1.5;
        top: 2.75rem; // 44
        text-transform: uppercase;
        letter-spacing: 0.083em;
      }
    }

    &__title {
      flex-grow: 1;
    }

    &__image {
      flex: 0 0 auto;

      img {
        margin-left: 0.9375rem; // 15
        width: 4.6875rem; // 75
      }
    }

    &:hover{
      #{$this}__title {
        // opacity: 0.5;
        color: $primary;
      }
    }
  }

  &__links {
    color: $primary;
    margin-top: $spacer;

    a {
      @include button-arrow-right($color-scheme: primary);
      &::after{
        transition: transform 100ms;
      }
      &:hover {
        &::after{
          transform: translate(5px, -50%);
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: column;

    &__list-wrapper {
      flex-grow: 1;
      overflow: auto;
    }

    &__list {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
}