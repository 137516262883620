@mixin home-page() {
  body.home & {
    @content;
  }
}

@mixin dark-theme() {
  .dark-theme & {
    @content;
  }
}

@mixin border-top-themed() {
  border-top: $border-width solid $gray-200;

  @include dark-theme() {
    border-top-color: $gray-800;
  }
}

@mixin border-right-themed() {
  border-right: $border-width solid $gray-200;

  @include dark-theme() {
    border-right-color: $gray-800;
  }
}

@mixin border-bottom-themed() {
  border-bottom: $border-width solid $gray-200;

  @include dark-theme() {
    border-bottom-color: $gray-800;
  }
}

@mixin border-left-themed() {
  border-left: $border-width solid $gray-200;

  @include dark-theme() {
    border-left-color: $gray-800;
  }
}

@mixin card-themed() {
  background-color: $gray-100;
  color: $body-color;

  @include dark-theme() {
    background-color: $gray-800;
    color: $white;
  }
}