.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($gray-900, 0.95);
  z-index: $zindex-modal;
  display: none;

  &.show { display: block; }

  &__close-button {
    @extend %unbutton;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    left: 0;
    margin: $spacer;
    width: $video-popup-button-size;
    height: $video-popup-button-size;
    background: escape-svg($video-popup-close-icon) center / contain no-repeat;
    z-index: 10;
  }

  &__iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    width: 100vmin;
    height: 100vmin;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0;
  }

  @include media-breakpoint-up(md) {
    &__close-button {
      margin: $spacer * 1.5;
    }

    &__iframe {
      top: $spacer * 5;
      left: $spacer * 5;
      width: subtract(100vw, $spacer * 10);
      height: subtract(100vh, $spacer * 10);
      transform: none;
    }
  }
}