.breadcrumb-outer {
  @include border-bottom-themed();
}

.breadcrumb {
  @extend %container;
  @include font-size($breadcrumnb-font-size);
  padding-top: $spacer * 1.25;
  padding-bottom: $spacer * 1.25;

  ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: inline-block;

    &:not(:first-child)::before {
      content: ' / ';
    }

    &.active {
      a {
        color: $primary;
      }
    }
  }
}
