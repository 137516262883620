%container {
  width: 100%;
  max-width: $container-max-width;
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-up(md) {
    padding-left: $grid-gutter-width-md;
    padding-right: $grid-gutter-width-md;
  }

  @include media-breakpoint-up(xl) {
    padding-left: $grid-gutter-width-xl;
    padding-right: $grid-gutter-width-xl;
  }
}
