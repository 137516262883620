.related-links {
  @extend %container;
  margin-top: $spacer * 2;
  margin-bottom: $spacer * 3;

  .row {
    margin-top: -$spacer;
    padding: 0.1px; // encap margin
  }

  &__title {
    margin-bottom: $spacer * 2;
  }

  &__link {
    $this: &;
    @include card-themed();
    position: relative;
    display: flex;
    margin-top: $spacer;
    min-height: $square-button-side;

    &:hover {
      background-color: $primary;
      color: $body-color;

      #{$this}__button::after {
        @include block-arrow-animation();
      }
    }

    &__text {
      padding: $related-link-padding-y ($spacer * 0.75);
      flex-grow: 1;
      align-self: center;
    }

    &__button {
      position: relative;
      width: $square-button-side;
      background: $primary;
      overflow: hidden;

      &::after {
        @include the-button-arrow();
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacer * 4;

    .row {
      @include make-row-resp();
    }
  
    .col {
      @include make-col-ready-resp();
      @include make-col(6);
    }
  }

  @include media-breakpoint-up(xl) {
    @include font-size($font-size-lg);

    &__link {
      min-height: $square-button-side-xl;

      &__text {
        padding: $related-link-padding-y $spacer;
      }

      &__button {
        width: $square-button-side-xl;
      }
    }
  }
}
