.sns-list {
  line-height: 0;

  &__sns {
    position: relative;
    display: inline-block;
    width: $sns-link-button-side;
    height: $sns-link-button-side;
    border-radius: 50%;
    background-color: $primary;

    &:not(:first-child) {
      margin-left: $spacer * 0.5;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: center / $sns-link-icon-side no-repeat;
    }

    @each $network, $svg in $social-icons {
      &.#{$network}::after {
        background-image: escape-svg($svg);
      }
    }

    &:hover{
      background-color: $black;
      @each $network, $svg in $social-icons-hover {
        &.#{$network}::after {
          background-image: escape-svg($svg);
        }
      }
    }
  }

  &--darkable &__sns {
    @include dark-theme() {
      background-color: $gray-800;

      @each $network, $svg in $social-icons-for-dark-theme {
        &.#{$network}::after {
          background-image: escape-svg($svg);
        }
      }
      &:hover{
        background-color: $primary;
        @each $network, $svg in $social-icons-for-dark-theme-hover {
          &.#{$network}::after {
            background-image: escape-svg($svg);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__sns {
      width: $sns-link-button-side-xl;
      height: $sns-link-button-side-xl;
    }
  }
}
