.hnav-outer {
  @extend %background-themed;
  @include border-bottom-themed();
  position: sticky;
  top: 0;
  z-index: 200;

  @include home-page() {
    border-bottom-color: transparent;
  }
}

.hnav {
  @extend %container;
  display: flex;
  align-items: center;
  height: $navbar-height;

  &__hamburger-button {
    @extend %unbutton;
    background: escape-svg($hamburger-close-icon-black) center / contain no-repeat;
    width: $symbol-side;
    height: $symbol-side;
    margin-right: $spacer;

    &.closed {
      background-image: escape-svg($hamburger-menu-icon-black);
    }

    @include dark-theme() {
      background-image: escape-svg($hamburger-close-icon-white);

      &.closed {
        background-image: escape-svg($hamburger-menu-icon-white);
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;
    margin-right: auto;
    height: 100%;

    &__parent {
      img {
        height: $navbar-parent-logo-height;

        &.white { display: none; }
        &.black { display: inline; }

        @include dark-theme() {
          &.white { display: inline; }
          &.black { display: none; }
        }
      }
    }

    &__sep {
      @include border-right-themed();
      margin-right: $spacer * 0.625;
      margin-left: $spacer * 0.75;
      height: 100%;

      @include home-page {
        height: subtract(100%, $spacer * 1.5);
      }
    }

    &__self {
      @extend %h2-adaptive-sm;
      color: $primary !important;
    }
  }

  &__right {
    display: none;
  }

  @include media-breakpoint-up(md) {
    height: $navbar-height-md;

    &__left {
      &__parent {
        img {
          height: $navbar-parent-logo-height-md;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__hamburger-button {
      display: none;
    }

    &__right {
      display: flex;
      align-items: center;

      &__menu {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      &__menu-item {
        display: inline-block;
        margin-left: $spacer * 1.25;

        &:hover{
          color: $primary;
        }

        &.active {
          color: $primary;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__left {
      &__sep {
        margin-right: $spacer * 1.25;
        margin-left: $spacer * 1.5;
      }
    }
  }
}
