.hr {
  @extend %container;

  &__inner {
    @include border-bottom-themed();
  }

  &--fw {
    max-width: none !important;
    padding-left: 0;
    padding-right: 0;
  }
}
