.image-slider {
  margin-top: $spacer * 3;
  margin-bottom: $spacer * 4;
  overflow-x: hidden;

  img {
    width: 100%;
  }

  &__container {
    @extend %container;
    position: relative;
  }

  &__arrow {
    @extend %unbutton;
    display: block;
    position: absolute;
    top: 47%; // approx., will be adjusted by JS
    transform: translate(0, -50%);
    background-color: $primary;
    width: $square-button-side;
    height: $square-button-side;
    z-index: 10;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &--prev {
      &::after {
        @include the-button-arrow('left');
      }
    }

    &--next {
      right: add($grid-gutter-width, $image-slider-peaking-width);

      &::after {
        @include the-button-arrow('right');
      }
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__center-frame {
    position: relative;
    white-space: nowrap;
    transition: transform .6s;
  }

  &__slide {
    display: inline-block;
    margin: 0; // from <figure>
    width: subtract(100%, $image-slider-peaking-width);
    transition: opacity .4s;
    text-align: center;

    &:not(:first-child) {
      @include gutter-multiplier(1, 'margin-left');
    }

    &:not(.active) {
      opacity: 0.25;
    }

    figcaption {
      @include font-size($image-slider-caption-font-size);
      margin-top: $spacer * 0.5;
    }
  }

  @include media-breakpoint-up(md) {
    &__arrow {
      &--next {
        right: add($grid-gutter-width-md, $image-slider-peaking-width-md);
      }
    }

    &__slide {
      width: subtract(100%, $image-slider-peaking-width-md);
    }
  }

  // no peaking effect starting from lg
  @include media-breakpoint-up(lg) {
    &__arrow {
      &--next {
        right: $grid-gutter-width-md;
      }
    }

    &__slide {
      width: 100%
    }
  }

  @include media-breakpoint-up(xl) {
    &__arrow {
      width: $square-button-side-xl;
      height: $square-button-side-xl;

      &--next {
        right: $grid-gutter-width-xl;
      }
    }
  }
}
