.download-cell {
  @include font-size($font-size-base / 18 * 15);

  // each element possess its own margin-top, but not all element appears,
  // so always remove the first element's margin-top
  > *:first-child {
    margin-top: 0 !important;
  }

  &__image {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    @extend %h4-adaptive-xs;
    line-height: 1.3;
    margin-top: $spacer * 0.4;
  }

  &__links {
    margin-top: $spacer * 0.3;
    color: $primary;
  }

  &__link {
    @extend %h4-adaptive-xs;
    @include button-arrow-left(right, primary);
    line-height: 1.3;
    display: block;
  }

  &__text {
    margin-top: $spacer * 0.5;
  }

  @include media-breakpoint-up(lg) {
    &__image {
      margin-bottom: $spacer;
    }

    &__title {
      margin-top: $spacer;
    }

    &__links {
      margin-top: $spacer * 0.75;
    }

    &__text {
      margin-top: $spacer;
    }
  }
}
