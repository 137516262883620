.article-header {
  @extend %container;
  margin-top: $spacer * 1.75;
  margin-bottom: $spacer * 1.75;

  & + * {
    margin-top: 0 !important;
  }

  &__date {
    color: $text-muted;
    margin-bottom: $spacer;
  }

  &__title {
    @include font-size($h2-font-size);
  }

  &__sharers {
    margin-top: $spacer * 1.5;
  }
}
