.video-grid {
  @extend %container;
}

.video-row {
  @include make-row-resp();

  &__top,
  &__meta,
  &__main,
  &__bottom {
    @include make-col-ready-resp();
    @include make-col(12);
  }

  &__top {
    @include gutter-multiplier(1, 'margin-bottom');

    &__hr {
      @include border-bottom-themed();
    }
  }

  &__bottom {
    @include gutter-multiplier(2, 'margin-bottom');
  }

  @include media-breakpoint-down(sm) {
    &__meta { // add some spacing between meta and main
      margin-bottom: $spacer * 2;
    }
  }

  @include media-breakpoint-up(md) {
    &__meta {
      @include make-col(4);
      margin-bottom: 0;
    }
  
    &__main {
      @include make-col(8);
    }
  }

  @include media-breakpoint-up(xl) {
    &__top {
      margin-bottom: $grid-gutter-width-xl;
    }

    &__bottom {
      margin-bottom: $grid-gutter-width-xl * 2;
    }
  }
}

.video-meta {
  &__date {
    @include font-size($h3-font-size);
    color: $text-muted;
  }

  &__title {
    @include font-size($h3-font-size);
  }

  &__avatar {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-top: $spacer;
  }

  &__supplement {
    color: $text-muted;
    margin-top: $spacer * 0.75;
  }

  @include media-breakpoint-up(xl) {
    &__date,
    &__title {
      line-height: 1.19;
    }

    &__avatar {
      width: 150px;
      height: 150px;
    }
  }
}

.video-list {
  @include make-row-resp();
  @include gutter-multiplier(-1, 'margin-bottom');
  list-style: none;
  padding: 0;
  margin-top: 0;

  &__item {
    @include make-col-ready-resp();
    @include make-col(6);
    @include gutter-multiplier(1, 'margin-bottom');

    >*:only-child {
      height: 100%;
    }
  }
}

.video-cell {
  $this: &;
  @include card-themed();
  position: relative;
  display: block;
  padding: ($spacer * 0.5) ($spacer * 0.75)
    add($spacer * 0.5, $square-button-side * 5 / 6) ($spacer * 0.75);

  & > * {
    position: relative;
    z-index: 10;
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 5;
    background: center / cover no-repeat;
  }

  &__title {
    @extend %h4-adaptive-xs;
    min-height: 5.5em; // empirical
  }

  &__button {
    position: absolute;
    right: 0;
    bottom: 0;
    width: $square-button-side * 5 / 6;
    height: $square-button-side * 5 / 6;
    background-color: $primary;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: $button-arrow-width;
      height: $button-arrow-width;
      transform: translate(-50%, -50%);
      background: escape-svg($video-cell-play-icon) center / contain no-repeat;
    }
  }

  &:hover {
    color: $primary;
    
    #{$this}__background {
      opacity: 0.25;
    }

    #{$this}__button::after {
      @include block-arrow-animation();
    }
  }

  @include media-breakpoint-up(md) {
    padding: ($spacer * 0.75) ($spacer)
      add($spacer * 0.75, $square-button-side) ($spacer);

    &__button {
      width: $square-button-side;
      height: $square-button-side;
    }
  }

  @include media-breakpoint-up(xl) {
    padding: ($spacer * 1.25) ($spacer * 1.5)
      add($spacer * 1.25, $square-button-side-xl) ($spacer * 1.5);

    &__button {
      width: $square-button-side-xl;
      height: $square-button-side-xl;
    }
  }
}
